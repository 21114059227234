import { formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import {connect} from "react-redux";
import Accordion from  '../../../components/common/accordion/accordion';

const PatientNurseNote = ({serviceID, serviceName, handleRemount, loginData, setNurseNoteFormData}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [noteList, setNoteList] = useState([])

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        let url = serviceName === "Consultation" ? `${serverLink}patient/consultation/nurse-note/${serviceID}`  : `${serverLink}admission/nurse-note/${serviceID}`;
        await axios.get(url, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setNoteList(result.data);
                }else {
                    setNoteList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h3 className="card-title">Nurse's Note</h3>
                    {
                        noteList.length > 0 ?  noteList.map((item, index) => {
                            return (
                                <Accordion
                                    index={index}
                                    header={`${formatDateAndTime(item.submittedOn, 'date_and_time')} => Nurse: ${item.NurseName}`}
                                    content={
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Observation Note</th>
                                                    <th>Morning Shift Report</th>
                                                    <th>Afternoon Shift Report</th>
                                                    <th>Night Shift Report</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>{index +1}</td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note1}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note2}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note3}}></td>
                                                    <td dangerouslySetInnerHTML={{__html: item.note4}}></td>
                                                    <td>
                                                        {loginData[0]?.userID !== item.nurseID ? <>--</> :
                                                            <a href="#" className={"btn btn-primary btn-sm "}
                                                               data-toggle="modal" data-target="#nurse-note-modal"
                                                               onClick={() => {
                                                                   setNurseNoteFormData({
                                                                       nurseNoteID: item.nurseNoteID,
                                                                       appointmentID: item.appointmentID,
                                                                       admissionID: item.admissionID,
                                                                       patientSerial: item.patientSerial,
                                                                       nurseID: item.nurseID,
                                                                       note1: item.note1,
                                                                       note2: item.note2,
                                                                       note3: item.note3,
                                                                       note4: item.note4,
                                                                   })
                                                               }}>
                                                                Edit
                                                            </a>
                                                        }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                />
                            )
                        }) : <div className="alert alert-info text-center p-3"><h3>No Record Found!</h3></div>
                    }

                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientNurseNote);


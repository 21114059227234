import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function ClinicalTask(props) {
    const { patientSerial, patientName, serviceID, serviceName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        doctorID: `${props.loginData[0]?.userID}`,
        task: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const getItemID = (service_name, service_id) => {
        if (service_name === 'Consultation'){
            return {appointmentID: service_id, admissionID: null}
        }else if (service_name === 'Admission'){
            return {admissionID: service_id, appointmentID: null}
        }else{
            return {}
        }
    }

    const addTask = async () => {
        if (formData.task.toString().trim() === ''){
            toast.error('Please enter clinical task')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            ...getItemID(serviceName, serviceID)
        }

        await axios.post(`${serverLink}patient/appointment/task/add`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Task Added Successfully");
                setIsFormLoading(false);
                props.setRebuildTask(generate_token(5));
                document.getElementById("closeTask").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding Task. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Clinical Task</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="task"
                                    className="form-control"
                                    id="task"
                                    value={formData.task}
                                    onChange={onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addTask}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ClinicalTask);
import React, {useEffect, useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import PrescriptionAssessmentForm from "./prescription-assessment-form";

function PrescriptionAssessment(props) {
    const { patientSerial, patientName, serviceID, serviceName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [prescription, setPrescription] = useState([])
    const [prescriptionList, setPrescriptionList] = useState([])
    const [clickedItems, setClickedItems] = useState({})

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        timeGiven: '',
        doseGiven: "",
        prescriptionID: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        const url = !serviceID ? `${serverLink}pharmacy/prescription/request/${patientSerial}` : `${serverLink}pharmacy/prescription/list/${patientSerial}/${serviceID}`
        await axios.get(url, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setPrescription(result.data.prescription);
                    setPrescriptionList(result.data.items);
                }else {
                    setPrescription([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const resetFormData = () => {
        setFormData(Initial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const getItemID = (service_name, service_id) => {
        if (service_name === 'Consultation'){
            return {appointmentID: service_id, admissionID: null}
        }else if (service_name === 'Admission'){
            return {admissionID: service_id, appointmentID: null}
        }else{
            return {}
        }
    }

    const addPrescriptionAssessment = async () => {

        if (formData.timeGiven.toString().trim() === ''){
            toast.error('Please select time taken')
            return false
        }
        if (formData.doseGiven.toString().trim() === ''){
            toast.error('Please select dose given')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            ...getItemID(serviceName, serviceID)
        }

        await axios.post(`${serverLink}pharmacy/prescription/assessment/add`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Prescription Assessment Added Successfully");
                setIsFormLoading(false);
                 props.setRebuildPrescriptionAssessment(generate_token(5));
                document.getElementById("closePrescriptionAssessmentFormModal").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding Prescription-assessment. Please try again!");
            }
        }).catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="mb-3 form-group col-md-12">
                                {
                                    prescriptionList.length > 0 ?
                                        <div>
                                            <table className="table">
                                                <thead>
                                                    <tr className="bg-dark text-white">
                                                        <th>Drug Name</th>
                                                        <th>Quantity</th>
                                                        <th>Dose</th>
                                                        <th>Prescription</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        prescriptionList.map((item, index)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{item.drugName}</td>
                                                                    <td>{item.drugQuantity}</td>
                                                                    <td>{item.drugDose} {item.doseFrequency}</td>
                                                                    <td>{item.prescription}</td>
                                                                    <td><button type="button" className='btn btn-info btn-sm float-end'  data-toggle="modal" data-target="#PrescriptionAssessmentFormModal"
                                                                                onClick={() => {
                                                                                    setFormData({
                                                                                        ...formData,
                                                                                        drugPrescriptionID: item.drugPrescriptionID,
                                                                                        prescriptionID: item.prescriptionID,
                                                                                        drugID: item.drugID,
                                                                                        drugName: item.drugName,
                                                                                        drugDose: item.drugDose,
                                                                                        doseFrequency: item.doseFrequency,
                                                                                        prescription: item.prescription,
                                                                                        drugQuantity: parseInt(item.drugQuantity),
                                                                                    })}}><span className="fa fa-plus"></span></button></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        :
                                        <div>
                                            No record found
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <PrescriptionAssessmentForm
            id="PrescriptionAssessmentFormModal"
            title="Prescription Assessment Form"
            close="closePrescriptionAssessmentFormModal"
            clickedItems={clickedItems}
            IsFormLoading={IsFormLoading}
            formData={formData}
            onSubmit={addPrescriptionAssessment}
            onEdit={onEdit}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PrescriptionAssessment);
import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import GalleryForm from "./gallery-form";
import {Link} from "react-router-dom";

function Gallery(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [galleryList, setGalleryList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const header = ["S/N", "Title", "Images", "Category", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        EntryID: "",
        ImageTitle: "",
        ImagePath: "",
        ImageData: "",
        CategoryID: "",
        CategoryName: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}gallery/list`).then((result) => {
            if (result.data.Gallery.length > 0) {
                setGalleryList(result.data.Gallery);
            }else{
                setGalleryList([])
            }
            setCategoryList(result.data.Category);
            setIsLoading(false)
        })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}gallery/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return galleryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.title}</td>
                        <td> <img src={`${serverLink}public/uploads/gallery_uploads/${item.image}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td>{item.categoryName}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.galleryID,
                                       CategoryID: item.categoryID,
                                       CategoryName: item.categoryName,
                                       ImageTitle: item.title,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.galleryID)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }


    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };


    const onSubmit = async () => {
        if (formData.ImageData.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select image", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}gallery/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {

                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', result.data.entry_id)
                            axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData2)
                                .then(result => {
                                    toast.success("Gallery Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        ImageTitle: "",
                                        ImagePath: "",
                                        CategoryID: "",
                                        CategoryName: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}gallery/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', formData.EntryID)
                            axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData2)
                                .then(result => {
                                    toast.success("Gallery Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        ImageTitle: "",
                                        ImagePath: "",
                                        CategoryID: "",
                                        CategoryName: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("Slider Updated Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("closeModal").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                ImageTitle: "",
                                ImagePath: "",
                                CategoryID: "",
                            });
                        }
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }


    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Gallery"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Gallery</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group mr-3">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        EntryID: "",
                                                        ImageTitle: "",
                                                        ImagePath: "",
                                                        CategoryID: "",
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Gallery
                                                </button>
                                            </div>
                                            <div className="btn-group">
                                                <Link to="/website/gallery/category" className="btn btn-info">
                                                    <i className="fa fa-sitemap"></i> Gallery Category
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="Gallery" header={header} body={showTable()} title="Website Gallery Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GalleryForm
                    title="Manage Gallery"
                    formData={formData}
                    categoryList={categoryList}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    onImageChange={onImageChange}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Gallery);


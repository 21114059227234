import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";

import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import {PatientInfoCard} from "../utils/patient-info-card";
import PatientAppointments from "../utils/patient-appointments";
import PatientAllergies from "../utils/patient-allergies";
import PatientVitals from "../utils/patient-vitals";
import PatientDependants from "../utils/patient-dependants";
import PatientPayments from "../utils/patient-payments";
import PatientServices from "../utils/patient-services";
import PatientPreviousHospitalRecord from "../utils/patient-previous-hospital-record";
import PatientCertificates from "../utils/patient-certificate";
import PatientOtherInfo from "../utils/patient-other-info";
import BookAppointment from "../components/book-appointment";
import Allergy from "../components/allergy";
import CheckInPatient from "../components/check-in-patient";
import CaptureVitals from "../components/capture-vitals";
import PreviousHospitalRecord from "../components/previous-hospital-record";
import OtherInformation from "../components/other-information";
import RequestService from "../components/service-request";
import BirthCertificate from "../components/birth-certificate";
import DeathCertificate from "../components/death-certificate";
import PatientCard from "../components/patient.card";
import MenuRoute from "../../common/card/menu-route";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";

function PatientDashboard(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [vitalFormData, setVitalFormData] = useState({})
    const [rebuildAppointment, setRebuildAppointment] = useState(null);
    const [rebuildVitals, setRebuildVitals] = useState(null);
    const [rebuildCheckIn, setRebuildCheckIn] = useState(null);
    const [rebuildAllergy, setRebuildAllergy] = useState(null);
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [rebuildPreviousRecord, setRebuildPreviousRecord] = useState(null);
    const [rebuildOtherInformation, setRebuildOtherInformation] = useState(null);
    const [rebuildServiceRequest, setRebuildServiceRequest] = useState(null);
    const [rebuildBirthCert, setRebuildBirthCert] = useState(null);

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/consultation/${atob(slug)}`, token)
            .then(async (result) => {
                const patient = result.data;
                console.log(patient)
                if (patient.length > 0){
                    setPatientDetails(patient[0])
                }else{
                    navigate('/')
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Patient Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Patient Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-profile-tab" data-toggle="pill"
                                           href="#last-month"
                                           role="tab" aria-controls="pills-profile"
                                           aria-selected="false">Appointments</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-allergies-tab" data-toggle="pill"
                                           href="#allergies" role="tab" aria-controls="pills-allergies"
                                           aria-selected="false">Allergies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital"
                                           role="tab" aria-controls="pills-vital" aria-selected="false">Vitals</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-payments-tab" data-toggle="pill"
                                           href="#payments"
                                           role="tab" aria-controls="pills-payments" aria-selected="false">Payments</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard patientDetails={patientDetails}/>
                                                <div className="col-md-8">
                                                    {/*<h4 className="card-title m-t-5">Menu</h4>*/}
                                                    <div className="row">
                                                        <MenuCard title="Book Appointment" icon="ti-calendar"
                                                                  size="col-md-6" color="info"
                                                                  modal="appointment-modal"/>
                                                        <MenuCard title="Check In Patient" icon="ti-check-box"
                                                                  size="col-md-6" color="cyan" modal="check-in-modal"/>
                                                        <MenuCard title="Capture Vitals" icon="fa-vials" size="col-md-6"
                                                                  color="success" modal="vital-modal"/>
                                                        <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                                                                  size="col-md-6" color="orange" modal="allergy-modal"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="last-month" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">
                                        <div className="card-body">
                                            <PatientAppointments patientSerial={patientDetails.patientSerial} handleRemount={rebuildAppointment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="allergies" role="tabpanel"
                                         aria-labelledby="pills-allergies-tab">
                                        <div className="card-body">
                                            <PatientAllergies patientSerial={patientDetails.patientSerial}
                                                              />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="vital" role="tabpanel"
                                         aria-labelledby="pills-vital-tab">
                                        <div className="card-body">
                                            <PatientVitals patientSerial={patientDetails.patientSerial} setVitalFormData={setVitalFormData} handleRemount={rebuildVitals} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <PatientPayments patientSerial={patientDetails.patientSerial} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BookAppointment
                    id="appointment-modal"
                    title="Add Appointment"
                    close="closeAppointment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAppointment={setRebuildAppointment}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildCheckIn={setRebuildCheckIn}
                />
                <CheckInPatient
                    id="check-in-modal"
                    title="Check In Patient"
                    close="closeCheckIn"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAppointment={setRebuildAppointment}
                    handleRemount={rebuildCheckIn}
                />
                <CaptureVitals
                    id="vital-modal"
                    title="Capture Vital"
                    close="closeVital"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setVitalFormData={setVitalFormData}
                    vitalFormData={vitalFormData}
                    handleRemount={rebuildCheckIn}
                    setRebuildVitals={setRebuildVitals}
                />
                <Allergy
                    id="allergy-modal"
                    title="Add Allergy"
                    close="closeAllergy"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAllergy={setRebuildAllergy}
                />
                <PreviousHospitalRecord
                    id="record-modal"
                    title="Add Previous Hospital Record"
                    close="closeRecord"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildPreviousRecord={setRebuildPreviousRecord}
                />
                <OtherInformation
                    id="other-modal"
                    title="Patient Other Info"
                    close="closeOtherInfo"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildOtherInformation={setRebuildOtherInformation}
                />
            <RequestService
                    id="service-modal"
                    title="Request Service"
                    close="closeServiceRequest"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    patientEmail={patientDetails.emailAddress}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildServiceRequest={setRebuildServiceRequest}
                />
            <BirthCertificate
                    id="birth-modal"
                    title="Upload Birth Certificate"
                    close="closeBirthCertificate"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildBirthCert={setRebuildBirthCert}
                />
            <DeathCertificate
                    id="death-modal"
                    title="Upload Death Certificate"
                    close="closeDeathCertificate"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildBirthCert={setRebuildBirthCert}
                />
                <PatientCard
                    id="card-modal"
                    title="Patient Card"
                    close="closeCard"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.firstName + ' ' + patientDetails.middleName + ' ' + patientDetails.surname}
                    walletAmount={patientDetails.walletAmount}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(PatientDashboard);


import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import DepartmentForm from "./department-form";

function Department(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [sliderList, setSliderList] = useState([])
    const header = ["S/N", "Department Name", "Head Of Department", "phoneNumber", "emailAddress", "extensionNo", "submittedBy", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        departmentID: "",
        departmentName: "",
        departmentDescription: "",
        headOfDepartmentID: "",
        emailAddress: "",
        phoneNumber: "",
        extensionNo: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/settings/department/list/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setSliderList(result.data);
                }else {
                    setSliderList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}staff/settings/department/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return sliderList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.departmentName}</td>
                        <td>{item.headOfDepartmentID}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.extensionNo}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       departmentID: item.departmentID,
                                       departmentName: item.departmentName,
                                       headOfDepartmentID: item.headOfDepartmentID,
                                       emailAddress: item.emailAddress,
                                       phoneNumber: item.phoneNumber,
                                       extensionNo: item.extensionNo,
                                       departmentDescription: item.departmentDescription,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={()=>showConfirm("Warning", `Are you sure you want to delete this department?`, "warning")
                                   .then( async (confirm) => {
                                       if (confirm) {
                                           delete_item(item.departmentID)
                                       }
                                   })}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            departmentDescription: event,
        });
    }

    const onSubmit = async () => {
        if (formData.departmentName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter department name", "error");
            return false;
        }

        if (formData.headOfDepartmentID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select department head", "error");
            return false;
        }


        if (formData.departmentID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/settings/department/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            departmentID: "",
                            departmentName: "",
                            departmentDescription: "",
                            headOfDepartmentID: "",
                            emailAddress: "",
                            phoneNumber: "",
                            extensionNo: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/settings/department/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            departmentID: "",
                            departmentName: "",
                            departmentDescription: "",
                            headOfDepartmentID: "",
                            emailAddress: "",
                            phoneNumber: "",
                            extensionNo: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Department"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Department</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        departmentID: "",
                                                        departmentName: "",
                                                        departmentDescription: "",
                                                        headOfDepartmentID: "",
                                                        emailAddress: "",
                                                        phoneNumber: "",
                                                        extensionNo: "",
                                                    });
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Department
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="Department" header={header} body={showTable()} title="Department Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DepartmentForm
                    title="Manage Department"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    descriptionHandler={descriptionHandler}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(Department);


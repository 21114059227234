import React, { useState} from "react";
import {currencyConverter, serverLink} from "../../url";
import axios from "axios";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
function WalletTopUp(props) {
    const { patientSerial, patientName, walletAmount} = props;
    const navigate = useNavigate()
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const Initial = {
        patientSerial: patientSerial,
        patientName: patientName,
        paymentReference: '',
        paymentMethod: '',
        paymentAmount: 0,
        paymentStatus: "Paid",
        paymentFor: "Wallet TopUp",
        paymentType: "Credit",
        amountDue: 0,
        amountPaid: 0,
        itemName: "Fund Wallet",
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const creditWallet = async () => {
        if (formData.paymentMethod === ""){
            toast.error('Please select payment method')
            return false
        }
        if (formData.paymentReference.toString().trim() === ""){
            toast.error('Please enter payment reference')
            return false
        }
        if (formData.amountPaid.toString().trim() === '0'){
            toast.error('Please enter amount to credit')
            return false
        }


        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            patientSerial: patientSerial,
            patientName: patientName,
            paymentAmount: formData.amountPaid,
            amountDue: formData.amountPaid,
        }
        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then(async (res) => {
            if (res.data.message === "success") {
                toast.success("Wallet Credited Successfully");
                setIsFormLoading(false);
                document.getElementById("closewallet").click();
                resetFormData()
                setTimeout(()=>{
                    window.location.reload();
                }, 2000)
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }).catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`} id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Payment Method</label>
                                <select name="paymentMethod" className="form-control" id="paymentMethod"
                                        onChange={onEdit}
                                        value={formData.paymentMethod}>
                                    <option value="">Select Payment Method</option>
                                    <option value="POS">POS</option>
                                    <option value="Transfer">Transfer</option>
                                    <option value="Cash Deposit">Cash Deposit</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Payment Reference</label>
                                <input
                                    id="paymentReference"
                                    name="paymentReference"
                                    className="form-control"
                                    value={formData.paymentReference}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Amount Paid</label>
                                <input
                                    type="number"
                                    id="amountPaid"
                                    name="amountPaid"
                                    className="form-control"
                                    value={formData.amountPaid}
                                    step="0.01"
                                    min={0}
                                    onChange={onEdit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="alert alert-warning">Note: Once submitted, the transaction cannot be reverted!
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={() =>
                                    showConfirm(
                                        "Warning",
                                        `Are you sure you want to credit ${patientName}-(${patientSerial}) wallet?`,
                                        "warning"
                                    ).then(async (confirm) => {
                                        if (confirm) {
                                            await creditWallet();
                                        }
                                    })
                                } className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(WalletTopUp);
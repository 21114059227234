import React from "react";

export default function DrugSubSubCategoryForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Drug Category</label>
                                <select className="form-control" name="drugCategoryID" id="drugCategoryID"
                                        value={props.formData.drugCategoryID} onChange={props.onEdit}>
                                    <option value="">Select Drug Category</option>
                                    {
                                        props.drugCategoryList.length > 0 && props.drugCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.drugCategoryID}>{item.categoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Drug Sub Category</label>
                                <select className="form-control" name="drugSubcategoryID" id="drugSubcategoryID"
                                        value={props.formData.drugSubcategoryID} onChange={props.onEdit}>
                                    <option value="">Select Sub Drug Category</option>
                                    {
                                        props.drugSubCategoryList.length > 0 && props.drugSubCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.drugSubcategoryID}>{item.subCategoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Drug Sub Sub Category Name</label>
                                <input
                                    type="text"
                                    name="subCategoryName"
                                    className="form-control"
                                    id="subSubCategoryName"
                                    value={props.formData.subSubCategoryName}
                                    onChange={props.onEdit}
                                    placeholder="Sub Sub Category"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    cols={3}
                                    rows={3}
                                    name="description"
                                    className="form-control"
                                    id="description"
                                    value={props.formData.description}
                                    onChange={props.onEdit}
                                    placeholder="Description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import ImageUpload from "../../common/image-upload/image-upload";
function DeathCertificate(props) {
    const { patientSerial, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [ImagePath, setImagePath] = useState('')
    const [ImageDisplay, setImageDisplay] = useState('')


    const InitialValue = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        dateOfDeath: "",
        causeOfDeath: "",
        placeOfDeceased: "",
        pronouncedBy: "",
        certificateFile: "",
        ImagePath: "",
        ImageDisplay: "",
        deliveredBy: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(InitialValue)
    const resetFormData = () => {
        setFormData(InitialValue)
    }
    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const addCertificate = async () => {
        if (ImagePath === ''){
            toast.error("Please select certificate file")
            return false
        }
        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.get(`${serverLink}patient/death-certificate/verify/${formData.patientSerial}`)
            .then(async (res) => {
                if (res.data.length > 0){
                    toast.error("Death certificate has already been uploaded");
                }else{
                    await axios.post(`${serverLink}patient/death-certificate/upload`, formData, token).then(async (result) => {
                        if (result.data.message === "success") {
                            if (ImagePath !== "") {
                                const formData2 = new FormData();
                                formData2.append('photo', ImagePath);
                                formData2.append('entry_id', result.data.entry_id)
                                axios.patch(`${serverLink}patient/upload-death-certificate`, formData2)
                                    .then(async (res) => {
                                        toast.success("Death Certificate Added Successfully");
                                        setIsFormLoading(false);
                                        props.setRebuildBirthCert(generate_token(5));
                                        document.getElementById("closeDeathCertificate").click();
                                        setImagePath('')
                                        setImageDisplay('')
                                        resetFormData()
                                    })
                                    .catch(err => {
                                        setIsFormLoading(false);
                                        showAlert(
                                            "ERROR",
                                            "Something went wrong uploading the image. Please try again!",
                                            "error"
                                        );
                                    });
                            }else{
                                toast.success("Death Certificate Added Successfully");
                                setIsFormLoading(false);
                                props.setRebuildBirthCert(generate_token(5));
                                document.getElementById("closeDeathCertificate").click();
                                setImagePath('')
                                setImageDisplay('')
                                resetFormData()
                            }
                        } else {
                            setIsFormLoading(false);
                            toast.error("Something went wrong adding certificate. Please try again!");
                        }
                    }) .catch((error) => {
                        showAlert(
                            "NETWORK ERROR",
                            "Please check your connection and try again!",
                            "error"
                        );
                    });
                }
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Name Of Deceased</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Date Of Death <span className="text-danger">*</span></label>
                                <input
                                    name="dateOfDeath"
                                    type="date"
                                    className="form-control"
                                    id="dateOfDeath"
                                    max={new Date().toISOString().split('T')[0]}
                                    value={formData.dateOfDeath}
                                    onChange={onEdit}
                                    required
                                />
                            </div>


                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Cause Of Death <span className="text-danger">*</span></label>
                                <select id="causeOfDeath" name="causeOfDeath" className="form-control" value={formData.causeOfDeath} onChange={onEdit} required>
                                    <option value="">Select Cause Of Death</option>
                                    <option value="Natural Causes">Natural Causes</option>
                                    <option value="Trauma and Accidents">Trauma and Accidents</option>
                                    <option value="Infections">Infections</option>
                                    <option value="Complications from Medical Procedures">Complications from Medical Procedures</option>
                                    <option value="Neurological Disorders">Neurological Disorders</option>
                                    <option value="Circulatory System Disorders">Circulatory System Disorders</option>
                                    <option value="Respiratory Disorders">Respiratory Disorders</option>
                                    <option value="Cancer and Tumors">Cancer and Tumors</option>
                                    <option value="Gastrointestinal Disorders">Gastrointestinal Disorders</option>
                                    <option value="Genetic Disorders">Genetic Disorders</option>
                                    <option value="Endocrine Disorders">Endocrine Disorders</option>
                                    <option value="Autoimmune Disorders">Autoimmune Disorders</option>
                                    <option value="Malnutrition and Dehydration">Malnutrition and Dehydration</option>
                                    <option value="Other and Unknown Causes">Other and Unknown Causes</option>
                                </select>

                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Place Of Death <span className="text-danger">*</span></label>
                                <input
                                    name="placeOfDeceased"
                                    type="text"
                                    className="form-control"
                                    id="placeOfDeceased"
                                    value={formData.placeOfDeceased}
                                    onChange={onEdit}
                                    required
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="title">Pronounced By<span className="text-danger">*</span></label>
                                <input
                                    name="pronouncedBy"
                                    type="text"
                                    className="form-control"
                                    id="pronouncedBy"
                                    value={formData.pronouncedBy}
                                    onChange={onEdit}
                                    required
                                />
                            </div>

                            <div className="col-md-12 mb-3">
                                <ImageUpload
                                    maxSize={200}
                                    setImagePath={setImagePath}
                                    setImageDisplay={setImageDisplay}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addCertificate}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(DeathCertificate);
import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import {setGeneralDetails} from "../../../action/actions";
import WalletTopUp from "./wallet-topup-form";
import {FaNairaSign} from "react-icons/fa6";

function PatientList(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);
    const [formData, setFormData] = useState({
        patientID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        passport: "",
        ImagePath: "",
        emailAddress: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
        bloodGroup: "",
        maritalStatus: "",
        nextOfKinName: "",
        nextOfKinPhone: "",
        nextOfKinRelationship: "",
        residentialAddress: "",
        nationality: "",
        state: "",
        lga: "",
        patientSerial: "",
        occupation: "",
        altPhoneNumber: "",
        password: "",
        ward: "",
        zipCode: "",
        officeAddress: "",
        religion: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    });

    const header = [
        "S/N",
        "Patient ID",
        "Name",
        "Gender",
        "Phone Number",
        "Email Address",
        "State",
        "LGA",
        "Credit Wallet",
    ];

    useEffect(() => {
        getData().then((r) => {});
    }, [""]);

    const getData = async () => {
        await axios
            .get(`${serverLink}patient/list/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.state}</td>
                        <td>{item.lga}</td>
                        <td className="d-flex justify-content-center">
                            <a href="#"
                               className={"btn btn-primary btn-sm"}
                               data-toggle="modal"
                               data-target="#wallet-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       patientID: item.patientID,
                                       title: item.title,
                                       firstName: item.firstName,
                                       middleName: item.middleName,
                                       surname: item.surname,
                                       passport: item.passport,
                                       emailAddress: item.emailAddress,
                                       phoneNumber: item.phoneNumber,
                                       gender: item.gender,
                                       dateOfBirth: item.dateOfBirth,
                                       bloodGroup: item.bloodGroup,
                                       maritalStatus: item.maritalStatus,
                                       nextOfKinName: item.nextOfKinName,
                                       nextOfKinPhone: item.nextOfKinName,
                                       nextOfKinRelationship: item.nextOfKinRelationship,
                                       residentialAddress: item.residentialAddress,
                                       nationality: item.nationality,
                                       religion: item.religion,
                                       occupation: item.occupation,
                                       altPhoneNumber: item.altPhoneNumber,
                                       state: item.state,
                                       lga: item.lga,
                                       patientSerial: item.patientSerial,
                                       walletAmount: item.walletAmount,
                                       password: item.password,
                                       ward: item.ward,
                                       zipCode: item.zipCode,
                                       status: item.status,
                                       officeAddress: item.officeAddress,
                                   });
                               }}
                            >
                                <FaNairaSign size={25} />
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    return IsLoading ? (
        <Loader />
    ) : (
        <>
        <PageTitle title={["Home", "Wallet TopUp"]} />
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Patient List</h4>
                            <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                <div className="ml-auto">
                                </div>
                            </div>
                            <DataTable
                                tableID="Patient"
                                header={header}
                                body={showTable()}
                                title="Patient Report"
                    />
                </div>
            </div>
        </div>
        </div>
        </div>

            <WalletTopUp
                id="wallet-modal"
                title="Wallet TopUp"
                close="closewallet"
                patientSerial={formData.patientSerial}
                patientName={formData.firstName + ' ' + formData.middleName + ' ' + formData.surname}
                walletAmount={formData.walletAmount}
            />

</>
);
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientList);

import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { formatDateAndTime,  serverLink} from "../../url";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import { setGeneralDetails } from "../../../action/actions";
import {toast} from "react-toastify";
import {ListAltOutlined} from "@material-ui/icons";
import CaptureVitalForm from "./capture-vital-form";

function PatientCaptureVitals(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([]);
    const [rebuild, setRebuildVitals] = useState(null);

    const header = [
        "S/N",
        "Patient Name",
        "Patient ID",
        "Doctor Name",
        "Doctor ID",
        "Department",
        "Appointment Date",
        "Appointment Time",
        "Checked In"
    ];

    const Initial = {
        patientSerial: '',
        patientName: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect(() => {
        getData();
    }, [rebuild]);

    const getData = async () => {
        // toast.info('Please wait...')
        await axios
            .get(`${serverLink}patient/appointment/checked-in/today`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setPatientList(result.data);
                } else {
                    setPatientList([]);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const showTable = () => {
        try {
            return patientList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.patientName}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.doctorName}</td>
                        <td>{item.doctorID}</td>
                        <td>{item.departmentName}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td className="d-flex">
                            <a href="#" className={"btn btn-info btn-sm "} data-toggle="modal"
                               data-target="#vital-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       patientName: item.patientName,
                                       patientSerial: item.patientSerial
                                   })
                               }}>
                                <ListAltOutlined size={20} />  Capture Vital
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader/>
    ) : (
        <>
            <PageTitle title={["Home", "Capture Patient Vitals"]}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Capture Patient Vitals</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <DataTable
                                        tableID="Patient"
                                        header={header}
                                        body={showTable()}
                                        title="Capture Patient Vitals Report"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CaptureVitalForm
                id="vital-modal"
                title="Capture Vital"
                close="closeVital"
                patientSerial={formData.patientSerial}
                patientName={formData.patientName}
                setRebuildVitals={setRebuildVitals}
            />
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientCaptureVitals);

import React, { useState} from "react";
import {currencyConverter } from "../../url";
import {connect} from "react-redux";
import Loader from "../../common/loader";
function PrescriptionAssessmentForm(props) {
    const { clickedItems, onSubmit, onEdit, IsFormLoading, formData } = props;
    const [IsLoading, setIsLoading] = useState(false)


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Drug Name</label>
                                <input
                                    className="form-control"
                                    value={formData.drugName}
                                    disabled
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Quantity</label>
                                <input
                                    className="form-control"
                                    value={formData.drugQuantity}
                                    disabled
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Dosage</label>
                                <input
                                    className="form-control"
                                    value={`${formData.drugDose}/${formData.doseFrequency}`}
                                    disabled
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Prescription</label>
                                <input
                                    className="form-control"
                                    value={formData.prescription}
                                    disabled
                                />
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="exercise">Time Taken </label>
                                <select
                                    name="timeGiven"
                                    id="timeGiven"
                                    className="custom-select"
                                    value={formData.timeGiven}
                                    onChange={onEdit}>
                                    <option value="">Select Option</option>
                                    <option value="Morning">Morning</option>
                                    <option value="Afternoon">Afternoon</option>
                                    <option value="Evening">Evening</option>
                                    <option value="Night">Night</option>
                                </select>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="doseFrequency">Dose Given </label>
                                <select
                                    name="doseGiven"
                                    id="doseGiven"
                                    className="custom-select"
                                    value={formData.doseGiven}
                                    onChange={onEdit}>
                                    <option value="">Select Option</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="0.2ML">0.2ML</option>
                                    <option value="0.5ML">0.5ML</option>
                                    <option value="1ML">1ML</option>
                                    <option value="1.5ML">1.5ML</option>
                                    <option value="2ML">2ML</option>
                                    <option value="5ML">5ML</option>
                                    <option value="10ML">10ML</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        {/*<button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close*/}
                        {/*</button>*/}
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onSubmit}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PrescriptionAssessmentForm);
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import SpecimenItem from "./specimen-item";
import SpecimenEntry from "./specimen-entry";
const SpecimenComponent = ({numberOfSpecimens, setNumberOfSpecimens, formData, setFormData, onEdit, specimenList, specimenBottleList, specimenVolumeList}) => {

    const renderSpecimenComponents = () => {
        return numberOfSpecimens.map((item, index) => (
            <div key={index}>
                <SpecimenEntry
                    keys={index}
                    index={item.id}
                    name={item.name}
                    formData={formData}
                    setFormData={setFormData}
                    onEdit={onEdit}
                    specimenList={specimenList}
                    specimenBottleList={specimenBottleList}
                    specimenVolumeList={specimenVolumeList}
                    numberOfSpecimens={numberOfSpecimens}
                    setNumberOfSpecimens={setNumberOfSpecimens}
                />
                <SpecimenItem/>
            </div>
        ));
    };


    return <div className="col-md-12 m-0 p-0">
        {renderSpecimenComponents()}
    </div>
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(SpecimenComponent);

import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Select from "react-select";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import PrescriptionRequestForm from "./prescription-request-form";
import {projectAddress, projectEmail, projectName, projectPatientURL} from "../../resources/constants";
function ImagingRequest(props) {
    const {serviceID, serviceName} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsGettingSchedules, setIsGettingSchedules] = useState(true)
    const [imageList, setImageList] = useState([])
    const [imageLabList, setImageLabList] = useState([])
    const [imagingTypes, setImagingTypes] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [clickedItems, setClickedItems] = useState({})

    const appointmentInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        patientEmail: props.patientEmail,
        imageID: "",
        imageID2: "",
        imageName: "",
        labID: "",
        labID2: "",
        labName: "",
        price: 0,
        requestType: serviceName,
        requestID: serviceID,
        walletAmount: props.walletAmount,
        hospitalName: projectName,
        hospitalAddress: projectAddress,
        hospitalPatientURL: projectPatientURL,
        hospitalEmail: projectEmail,
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        doctorID: `${props.loginData[0]?.userID}`,
        doctorEmail: `${props.loginData[0]?.emailAddress}`,
        doctorName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(appointmentInitial)
    const resetFormData = () => {
        setFormData(appointmentInitial)
    }

    useEffect( () => {
        getData();
    }, []);

    useEffect( () => {
        setImagingTypes(imageList.filter(e=>e.lab_id === formData.labID))
        setIsGettingSchedules(false)
    }, [formData.labID]);

    const getData = async () => {
        await axios.get(`${serverLink}imaging/data`)
            .then((result) => {
                let imaging_lab = []; let imaging_list = [];
                result.data.imageLab.map((row) => {
                    imaging_lab.push({value: row.imagingLabID, label: row.labName})
                });

                result.data.imaging.map((row) => {
                    imaging_list.push({value: row.imagingTypeID, label: row.imageName, lab_id: row.labID, lab_name: row.imagingLab, amount: row.price, description: row.description})
                });

                setImageLabList(imaging_lab);
                setImageList(imaging_list);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const onChangeServiceType = (e) => {
        setIsGettingSchedules(true)
        const val = e.value;
        setFormData({
            ...formData,
            labID: val,
            labName: e.label,
            labID2: e,
            imageID: '',
            imageID2: '',
        })
    }

    const onChangeImagingType = (e) => {
        const val = e.value;
        setFormData({
            ...formData,
            imageID: val,
            imageName: e.label,
            imageID2: e,
            price: e.amount,
        })
    }


    const requestImaging = async () => {
        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}imaging/request`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Imaging Requested Successfully");
                setIsFormLoading(false);
                props.setRebuildImagingRequest(generate_token(5));
                document.getElementById("closeImagingRequest").click();
                setSelectedServices([])
                getData();
                resetFormData();
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding request. Please try again!");
            }
        }) .catch((error) => {
            console.log(error)
            setIsFormLoading(false);
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Imaging Lab</label>
                                <Select
                                    id="labID"
                                    name="labID"
                                    value={formData.labID2}
                                    onChange={onChangeServiceType}
                                    options={imageLabList}
                                    placeholder="Select Imaging Lab"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Imaging Name</label>
                                <Select
                                    id="imageID"
                                    name="imageID"
                                    value={formData.imageID2}
                                    onChange={onChangeImagingType}
                                    options={imagingTypes}
                                    placeholder="Select Imaging"
                                />
                            </div>
                            {
                                formData.imageID !== '' ?
                                    <div className="col-md-12">
                                        <div className="col-md-12 mb-3 mt-5" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                            <h3 style={{color: '#d09c7e', fontWeight: 'bold'}}>IMAGING TEST SELECTED</h3>
                                        </div>

                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>Image Name</th>
                                                    <td>{formData.imageName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Amount</th>
                                                    <td>{currencyConverter(formData.price)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Image Lab</th>
                                                    <td>{formData.labName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> : <></>
                            }

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={requestImaging}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <PrescriptionRequestForm
            id="PrescriptionRequestFormModal"
            title="Prescription Form"
            close="closePrescriptionRequestFormModal"
            clickedItems={clickedItems}
            setSelectedServices={setSelectedServices}
            selectedServices={selectedServices}
        />
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ImagingRequest);
import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {formatDateAndTime, getUserType, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {useParams} from "react-router-dom";
import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import CaptureVitals from "../../patient/components/capture-vitals";
import Allergy from "../../patient/components/allergy";
import RequestService from "../../patient/components/service-request";
import PatientServices from "../../patient/utils/patient-services";
import PatientPayments from "../../patient/utils/patient-payments";
import PatientVitals from "../../patient/utils/patient-vitals";
import PatientAllergies from "../../patient/utils/patient-allergies";
import PatientAppointments from "../../patient/utils/patient-appointments";
import {PatientInfoCard} from "../../patient/utils/patient-info-card";
import DoctorNote from "../components/doctor-note";
import PatientDoctorNote from "../utils/patient-doctor-note";
import NurseNote from "../components/nurse-note";
import PatientNurseNote from "../utils/patient-nurse-note";
import PrescriptionRequest from "../components/prescription-request";
import PatientPrescriptionRequest from "../utils/patient-prescription-request";
import PatientClinicalTask from "../utils/patient-clinical-task";
import ClinicalTask from "../components/clinical-task";
import ImagingRequest from "../components/imaging-request";
import PatientImagingRequest from "../utils/patient-imaging-request";
import OnlineConsultation from "../components/online-consultation";
import PatientOnlineConsultation from "../utils/patient-online-consultation";
import {toast} from "react-toastify";
import PainAssessment from "../components/pain-assessment";
import PatientPrescriptionAssessment from "../utils/patient-prescription-assessment";
import PrescriptionAssessment from "../components/prescription-assessment";
import PatientPainAssessment from "../utils/patient-pain-assessment";
import PatientAmountIncurred from "../utils/patient-amount-incurred";
import PatientAppointmentEncounters from "../utils/patient-appointment-encounters";
import {useNavigate} from "react-router";
import MenuRoute from "../../common/card/menu-route";
import ECTReactComponent from "../components/icd-diagnosis";
import PatientDiagnosis from "../utils/patient-diagnosis";
import Referral from "../components/referral";
import PatientReferral from "../utils/patient-referral";

function OutpatientDashboard(props) {
    const token = props.loginData[0]?.token;
    const navigate = useNavigate()
    const {slug} = useParams();
    const userType = getUserType(window.location.href, 4);
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState({})
    const [vitalFormData, setVitalFormData] = useState({})
    const [doctorFormData, setDoctorFormData] = useState({})
    const [nurseNoteFormData, setNurseNoteFormData] = useState({})
    const [rebuildAppointment, setRebuildAppointment] = useState(null);
    const [rebuildVitals, setRebuildVitals] = useState(null);
    const [rebuildCheckIn, setRebuildCheckIn] = useState(null);
    const [rebuildAllergy, setRebuildAllergy] = useState(null);
    const [rebuildPayment, setRebuildPayment] = useState(null);
    const [rebuildTask, setRebuildTask] = useState(null);
    const [rebuildServiceRequest, setRebuildServiceRequest] = useState(null);
    const [rebuildDoctorNote, setRebuildDoctorNote] = useState(null);
    const [rebuildNurseNote, setRebuildNurseNote] = useState(null);
    const [rebuildPrescription, setRebuildPrescription] = useState(null);
    const [rebuildImagingRequest, setRebuildImagingRequest] = useState(null);
    const [rebuildLink, setRebuildLink] = useState(null);
    const [rebuildPrescriptionAssessment, setRebuildPrescriptionAssessment] = useState(null);
    const [rebuildPainAssessment, setRebuildPainAssessment] = useState(null);
    const [rebuildDiagnosis, setRebuildDiagnosis] = useState(null);
    const [rebuildReferral, setRebuildReferral] = useState(null);

    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true)
                await axios
                    .get(`${serverLink}patient/consultation/detail/${atob(slug)}`, token)
                    .then(async (res) => {
                        if (res.data.length > 0) {
                            const patient_id = res.data[0].patientSerial;
                            await axios.get(`${serverLink}patient/consultation/${patient_id}`, token)
                                .then(async (result) => {
                                    const patient = result.data;
                                    const item = {
                                        walletAmount: patient[0].walletAmount,
                                        passport: patient[0].passport,
                                        firstName: patient[0].firstName,
                                        middleName: patient[0].middleName,
                                        surname: patient[0].surname,
                                        title: patient[0].title,
                                        emailAddress: patient[0].emailAddress,
                                        phoneNumber: patient[0].phoneNumber,
                                        altPhoneNumber: patient[0].altPhoneNumber,
                                        dateOfBirth: patient[0].dateOfBirth,
                                        bloodGroup: patient[0].bloodGroup,
                                        gender: patient[0].gender,
                                        status: patient[0].status,
                                        maritalStatus: patient[0].maritalStatus,
                                        nextOfKinName: patient[0].nextOfKinName,
                                        nextOfKinPhone: patient[0].nextOfKinPhone,
                                        nextOfKinRelationship: patient[0].nextOfKinRelationship,
                                        residentialAddress: patient[0].residentialAddress,
                                        lastVisit: patient[0].lastVisit,
                                        ...res.data[0]
                                    }
                                    setPatientDetails(item)
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    setIsLoading(false);
                                    showAlert(
                                        "NETWORK ERROR",
                                        "Please check your connection and try again!",
                                        "error"
                                    );
                                });
                        }else{
                            navigate(`/${userType}/outpatient`)
                        }
                    })
                    .catch((err) => {
                        console.log("NETWORK ERROR");
                    });

    }

    const showStatus = (status) => {
        switch (status) {
            case 'Scheduled':
                return <span className="badge badge-pill badge-info"><h3>{status}</h3></span>;
            case 'Checked In':
                return <span className="badge badge-pill badge-primary"><h3>{status}</h3></span>;
            case 'Completed':
                return <span className="badge badge-pill badge-danger"><h3> {status}</h3></span>;
            default:
            return <span className="badge badge-pill badge-secondary"><h3>{status}</h3></span>;
        }
    };


    const close_consultation = async (id) => {
        toast.info("please wait...");
        const sendData = {
            appointmentID: id,
            submittedBy: props.loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}patient/appointment/consultation/close`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Consultation marked as completed");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const goToAdmission = () => {
        navigate(`/admission/patient/${patientDetails.patientSerial}`)
    }

    const showMenu = (access) => {
        switch (access) {
            case 'Doctor':
                return <>
                    <MenuCard title="Doctor Note" icon="ti-pencil"
                              size="col-md-3" color="info"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "doctor-note-modal"}
                              onClick={() => setDoctorFormData({})}/>
                    <MenuCard title="Diagnosis (ICD-11)" icon="ti-search"
                              size="col-md-3" color="success"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "diagnosis-modal"}
                              onClick={() => setDoctorFormData({})}/>
                    <MenuCard title="Prescription" icon="fa-prescription-bottle-alt"
                              size="col-md-3" color="cyan"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "prescription-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Clinical Task" icon="fa-notes-medical"
                              size="col-md-3"
                              color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "task-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Imaging Request" icon="mdi mdi-microscope"
                              size="col-md-3"
                              color="cyan"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "imaging-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                              size="col-md-3" color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "allergy-modal"}/>
                    <MenuRoute title="Admission" icon="fa-bed" size="col-md-3"
                              color="info"
                              url={patientDetails.appointmentStatus === 'Completed' ? '#' : `/admission/patient/${btoa(patientDetails.patientSerial)}`}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed'){
                                      toast.error('This consultation is closed already')
                                      return false;
                                  }
                              }}/>
                    <MenuCard title="Service Request" icon="fa-cogs" size="col-md-3"
                              color="danger"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "service-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Pain Assessment" icon="fa-user"
                              size="col-md-3" color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "pain-modal"}/>
                    <MenuCard title="Referral" icon="fa-reply"
                              size="col-md-3" color="cyan"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "referral-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Online Consultation" icon="fa-user-md"
                              size="col-md-3"
                              color="secondary"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "online-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Close Case" icon="fa-check-circle"
                              size="col-md-3"
                              color="success"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "close-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') {
                                      toast.error('This consultation is closed already')
                                  } else {
                                      showConfirm("Warning", `Are you sure you want to close this consultation?`, "warning")
                                          .then(async (confirm) => {
                                              if (confirm) {
                                                  close_consultation(patientDetails.appointmentID)
                                              }
                                          })
                                  }
                              }}/>
                </>;
            case 'Nurse':
                return <>
                    <MenuCard title="Nurse Note" icon="ti-pencil"
                              size="col-md-3" color="info"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "nurse-note-modal"}
                              onClick={() => setNurseNoteFormData({})}/>
                    <MenuCard title="Clinical Task" icon="fa-notes-medical"
                              size="col-md-3"
                              color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "task-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>

                    <MenuCard title="Capture Vitals" icon="fa-vials" size="col-md-3"
                              color="success"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "vital-modal"}
                              onClick={() => setVitalFormData({})}/>
                    <MenuCard title="Add Allergy" icon="fa-briefcase-medical"
                              size="col-md-3" color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "allergy-modal"}/>
                    <MenuCard title="Service Request" icon="fa-cogs" size="col-md-3"
                              color="danger"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "service-modal"}
                              onClick={() => {
                                  if (patientDetails.appointmentStatus === 'Completed') toast.error('This consultation is closed already');
                              }}/>
                    <MenuCard title="Pain Assessment" icon="fa-user"
                              size="col-md-3" color="orange"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "pain-modal"}/>
                    <MenuCard title="Prescription Assessment" icon="fa-list"
                              size="col-md-3" color="cyan"
                              modal={patientDetails.appointmentStatus === 'Completed' ? 'blocked' : "prescription-assessment-modal"}/>
                </>;
            case 'Service-Desk':
                return <>

                </>;
            default:
                return <>

                </>;
        }
    };

    return (IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Consultation Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Outpatient Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-encounters-tab" data-toggle="pill"
                                           href="#encounters"
                                           role="tab" aria-controls="pills-encounters"
                                           aria-selected="false">Encounters</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-diagnosis-tab" data-toggle="pill"
                                           href="#diagnosis"
                                           role="tab" aria-controls="pills-diagnosis"
                                           aria-selected="false">Diagnosis</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-doctor-note-tab" data-toggle="pill"
                                           href="#doctor-note"
                                           role="tab" aria-controls="pills-doctor-note"
                                           aria-selected="false">Doctor Note</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-nurse-note-tab" data-toggle="pill"
                                           href="#nurse-note"
                                           role="tab" aria-controls="pills-nurse-note"
                                           aria-selected="false">Nurse Note</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-allergies-tab" data-toggle="pill"
                                           href="#allergies" role="tab" aria-controls="pills-allergies"
                                           aria-selected="false">Allergies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital"
                                           role="tab" aria-controls="pills-vital" aria-selected="false">Vitals Chart</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital"
                                           role="tab" aria-controls="pills-vital" aria-selected="false">Vitals
                                            Report</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-laboratory-tab" data-toggle="pill"
                                           href="#laboratory" role="tab" aria-controls="pills-laboratory"
                                           aria-selected="false">Laboratory</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-prescription-tab" data-toggle="pill"
                                           href="#prescription" role="tab" aria-controls="pills-prescription"
                                           aria-selected="false">Prescription</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-nurse-assessment-tab" data-toggle="pill"
                                           href="#nurse-assessment" role="tab" aria-controls="pills-nurse-assessment"
                                           aria-selected="false">Nurse Assessment</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-pain-assessment-tab" data-toggle="pill"
                                           href="#pain-assessment" role="tab" aria-controls="pills-pain-assessment"
                                           aria-selected="false">Pain Assessment</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-imaging-tab" data-toggle="pill"
                                           href="#imaging" role="tab" aria-controls="pills-imaging"
                                           aria-selected="false">Imaging</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-task-tab" data-toggle="pill"
                                           href="#task" role="tab" aria-controls="pills-task"
                                           aria-selected="false">Clinical Task</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-amount-incurred-tab" data-toggle="pill"
                                           href="#amount-incurred" role="tab" aria-controls="pills-amount-incurred"
                                           aria-selected="false">Amount Incurred</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-online-consultation-link-tab"
                                           data-toggle="pill"
                                           href="#online-consultation-link" role="tab"
                                           aria-controls="pills-online-consultation-link"
                                           aria-selected="false">Online Consultation Link</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-payments-tab" data-toggle="pill"
                                           href="#payments"
                                           role="tab" aria-controls="pills-payments" aria-selected="false">Payments</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-services-tab" data-toggle="pill"
                                           href="#services"
                                           role="tab" aria-controls="pills-services" aria-selected="false">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-referral-tab" data-toggle="pill"
                                           href="#referral"
                                           role="tab" aria-controls="pills-referral" aria-selected="false">Referral</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCard patientDetails={patientDetails}/>
                                                <div className="col-md-8">
                                                    {/*<h4 className="card-title m-t-5">Menu</h4>*/}
                                                    <div className="row">
                                                        <div className="col-md-12 bg-white mb-3">
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                            <div className="card-body">
                                                                <h4><b>Appointment Details</b></h4>
                                                                <hr/>
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">Department</span>
                                                                        <h5>{patientDetails.departmentName}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span className="text-muted p-t-30 db">Date</span>
                                                                        <h5>{formatDateAndTime(patientDetails.appointmentDate, 'date')}</h5>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <span
                                                                            className="text-muted p-t-30 db">Time/Slot</span>
                                                                        <h5>{patientDetails.appointmentTime}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showMenu(props.access)}
                                                        <div className="col-md-12 mt-5">
                                                            <div className="card">
                                                                <div className="card-body text-center">
                                                                    <h3><b>Consultation
                                                                        Status:</b> {showStatus(patientDetails.appointmentStatus)}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="last-month" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">
                                        <div className="card-body">
                                            <PatientAppointments patientSerial={patientDetails.patientSerial}
                                                                 handleRemount={rebuildAppointment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="doctor-note" role="tabpanel"
                                         aria-labelledby="pills-doctor-note-tab">
                                        <div className="card-body">
                                            <PatientDoctorNote appointmentID={patientDetails.appointmentID}
                                                               handleRemount={rebuildDoctorNote}
                                                               serviceName={"Consultation"}
                                                               setDoctorFormData={setDoctorFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="diagnosis" role="tabpanel"
                                         aria-labelledby="pills-diagnosis-tab">
                                        <div className="card-body">
                                            <PatientDiagnosis patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildDiagnosis}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nurse-note" role="tabpanel"
                                         aria-labelledby="pills-nurse-note-tab">
                                        <div className="card-body">
                                            <PatientNurseNote serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildNurseNote} setNurseNoteFormData={setNurseNoteFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="prescription" role="tabpanel"
                                         aria-labelledby="pills-prescription-tab">
                                        <div className="card-body">
                                            <PatientPrescriptionRequest patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildPrescription} setNurseNoteFormData={setNurseNoteFormData}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="imaging" role="tabpanel" aria-labelledby="pills-imaging-tab">
                                        <div className="card-body">
                                            <PatientImagingRequest patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildImagingRequest}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="task" role="tabpanel"
                                         aria-labelledby="pills-task-tab">
                                        <div className="card-body">
                                            <PatientClinicalTask patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildTask} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="referral" role="tabpanel"
                                         aria-labelledby="pills-referral-tab">
                                        <div className="card-body">
                                            <PatientReferral patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildReferral} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="allergies" role="tabpanel"
                                         aria-labelledby="pills-allergies-tab">
                                        <div className="card-body">
                                            <PatientAllergies patientSerial={patientDetails.patientSerial} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="vital" role="tabpanel"
                                         aria-labelledby="pills-vital-tab">
                                        <div className="card-body">
                                            <PatientVitals patientSerial={patientDetails.patientSerial} setVitalFormData={setVitalFormData} handleRemount={rebuildVitals} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="encounters" role="tabpanel" aria-labelledby="pills-encounters-tab">
                                        <div className="card-body">
                                            <PatientAppointmentEncounters patientSerial={patientDetails.patientSerial}  serviceName={"Consultation"} handleRemount={rebuildServiceRequest}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="pills-payments-tab">
                                        <div className="card-body">
                                            <PatientPayments patientSerial={patientDetails.patientSerial} handleRemount={rebuildPayment}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="services" role="tabpanel" aria-labelledby="pills-services-tab">
                                        <div className="card-body">
                                            <PatientServices patientSerial={patientDetails.patientSerial} handleRemount={rebuildServiceRequest}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nurse-assessment" role="tabpanel" aria-labelledby="pills-nurse-assessment-tab">
                                        <div className="card-body">
                                            <PatientPrescriptionAssessment patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildPrescriptionAssessment} />
                                         </div>
                                    </div>
                                    <div className="tab-pane fade" id="pain-assessment" role="tabpanel" aria-labelledby="pills-pain-assessment-tab">
                                        <div className="card-body">
                                            <PatientPainAssessment patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildPainAssessment} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="amount-incurred" role="tabpanel" aria-labelledby="pills-amount-incurred-tab">
                                        <div className="card-body">
                                            <PatientAmountIncurred patientSerial={patientDetails.patientSerial} serviceID={patientDetails.appointmentID} serviceName={"Consultation"} handleRemount={rebuildPainAssessment} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="online-consultation-link" role="tabpanel" aria-labelledby="pills-online-consultation-link-tab">
                                        <div className="card-body">
                                            <PatientOnlineConsultation patientSerial={patientDetails.patientSerial} appointmentID={patientDetails.appointmentID} handleRemount={rebuildLink} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DoctorNote
                    id="doctor-note-modal"
                    title="Doctor Note"
                    close="closeDoctorNote"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildDoctorNote={setRebuildDoctorNote}
                    doctorFormData={doctorFormData}
                />
                <NurseNote
                    id="nurse-note-modal"
                    title="Nurse Note"
                    close="closeNurseNote"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Admission"}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildNurseNote={setRebuildNurseNote}
                    nurseNoteFormData={nurseNoteFormData}
                />
                <PrescriptionRequest
                    id="prescription-modal"
                    title="Prescription"
                    close="closePrescription"
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildPrescription={setRebuildPrescription}
                />

                <ClinicalTask
                    id="task-modal"
                    title="Add Clinical Task"
                    close="closeTask"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    setRebuildTask={setRebuildTask}
                />
                <ImagingRequest
                    id="imaging-modal"
                    title="Imaging Request"
                    close="closeImagingRequest"
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildImagingRequest={setRebuildImagingRequest}
                />
                <CaptureVitals
                    id="vital-modal"
                    title="Capture Vital"
                    close="closeVital"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setVitalFormData={setVitalFormData}
                    vitalFormData={vitalFormData}
                    handleRemount={rebuildCheckIn}
                    setRebuildVitals={setRebuildVitals}
                />
                <Allergy
                    id="allergy-modal"
                    title="Add Allergy"
                    close="closeAllergy"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildAllergy={setRebuildAllergy}
                />
                <RequestService
                    id="service-modal"
                    title="Request Service"
                    close="closeServiceRequest"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    patientEmail={patientDetails.emailAddress}
                    setRebuildPayment={setRebuildPayment}
                    setRebuildServiceRequest={setRebuildServiceRequest}
                />

                <OnlineConsultation
                    id="online-modal"
                    title="Online Consultation Link"
                    close="closeOnline"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    setVitalFormData={setVitalFormData}
                    appointmentID={patientDetails.appointmentID}
                    setRebuildLink={setRebuildLink}
                />
                <PainAssessment
                    id="pain-modal"
                    title="Pain Assessment"
                    close="closePain"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    setRebuildPainAssessment={setRebuildPainAssessment}
                />
                <PrescriptionAssessment
                    id="prescription-assessment-modal"
                    title="Prescription Assessment"
                    close="closePrescriptionAssessment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    setRebuildPrescriptionAssessment={setRebuildPrescriptionAssessment}
                />
                <Referral
                    id="referral-modal"
                    title="Referral Form"
                    close="closeReferral"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    walletAmount={patientDetails.walletAmount}
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    setRebuildReferral={setRebuildReferral}
                />
                <ECTReactComponent
                    id="diagnosis-modal"
                    title="Diagnosis (ICD-11)"
                    close="closeDiagnosis"
                    serviceID={patientDetails.appointmentID}
                    serviceName={"Consultation"}
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    patientEmail={patientDetails.emailAddress}
                    walletAmount={patientDetails.walletAmount}
                    setRebuildDiagnosis={setRebuildDiagnosis}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(OutpatientDashboard);


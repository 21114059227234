import {BiSolidVial} from "react-icons/bi";
import {Link} from "react-router-dom";
import React from "react";

const SpecimenEntry = ({ keys, index, name, formData, setFormData,  onEdit, specimenList, specimenBottleList, specimenVolumeList, numberOfSpecimens, setNumberOfSpecimens }) => {
    const onDeleteSpecimen = (id) => {
        const filteredItems = numberOfSpecimens.filter(e=>e.id !== id)
        const filteredFormDataItems = formData.filter(e=>e.id !== id)
        setFormData(filteredFormDataItems)
        setNumberOfSpecimens(filteredItems)
    }
    return (
        <div className="card shadow">
            <div className="card-body">
                <div className="d-flex no-block align-items-center mt-3 ">
                    <h3><strong> <BiSolidVial/> {name} {keys+1}</strong></h3>
                    <div className="ml-auto">
                        <div className="btn-group">
                            {
                                keys === 0 ? <></> :
                                    <button onClick={()=>onDeleteSpecimen(index)} className="btn btn-danger">
                                        <i className="fa fa-trash-alt"></i>
                                    </button>
                            }

                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="mb-3 col-md-4 form-group">
                        <label className="form-label">Specimen Name</label>
                        <select className="form-control" name="labSpecimenID" id="labSpecimenID"
                                value={formData[keys].labSpecimenID} onChange={(e)=>onEdit(e, keys)}>
                            <option value="">Select Laboratory Name</option>
                            {
                                specimenList.length > 0 && specimenList.map((item, index) => {
                                    return (
                                        <option key={index}
                                                value={item.labSpecimenID}>{item.specimenName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3 form-group col-md-4">
                        <label className="form-label">Test Amount</label>
                        <input
                            type="number"
                            name="testAmount"
                            className="form-control"
                            id="testAmount"
                            value={formData[keys].testAmount}
                            onChange={(e)=>onEdit(e, keys)}
                            placeholder="Test Amount"
                        />

                    </div>
                    <div className="mb-3 col-md-4 form-group">
                        <label className="form-label">Specimen Bottle</label>
                        <select className="form-control" name="specimenBottle" id="specimenBottle"
                                value={formData[keys].specimenBottle} onChange={(e)=>onEdit(e, keys)}>
                            <option value="">Select Specimen Bottle</option>
                            {
                                specimenBottleList.length > 0 && specimenBottleList.map((item, index) => {
                                    return (
                                        <option key={index}
                                                value={item.bottleID}>{item.bottleName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3 col-md-4 form-group">
                        <label className="form-label">Specimen Bottle Volume</label>
                        <select className="form-control" name="specimenBottleVolume" id="specimenBottleVolume"
                                value={formData[keys].specimenBottleVolume} onChange={(e)=>onEdit(e, keys)}>
                            <option value="">Select Specimen Bottle Volume</option>
                            {
                                specimenVolumeList.length > 0 && specimenVolumeList.map((item, index) => {
                                    return (
                                        <option key={index}
                                                value={item.volumeID}>{item.volumeName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-3 col-md-4 form-group">
                        <label className="form-label">Turn Around Time</label>
                        <select className="form-control" name="specimenTurnAroundTime" id="specimenTurnAroundTime"
                                value={formData[keys].specimenTurnAroundTime} onChange={(e)=>onEdit(e, keys)}>
                            <option value="">Select Turn Around Time</option>
                            <option value="30 mins">30 mins</option>
                            <option value="1 hr">1 hr</option>
                            <option value="2 hrs">2 hrs</option>
                            <option value="3 hrs">3 hrs</option>
                            <option value="4 hrs">4 hrs</option>
                            <option value="5 hrs">5 hrs</option>
                            <option value="6 hrs">6 hrs</option>
                            <option value="7 hrs">7 hrs</option>
                            <option value="8 hrs">8 hrs</option>
                            <option value="9 hrs">9 hrs</option>
                            <option value="10 hrs">10 hrs</option>
                            <option value="11 hrs">11 hrs</option>
                            <option value="12 hrs">12 hrs</option>
                            <option value="24 hrs">24 hrs</option>
                            <option value="36 hrs">36 hrs</option>
                            <option value="48 hrs">48 hrs</option>
                            <option value="5 Days">5 Days</option>
                            <option value="7 Days">7 Days</option>
                            <option value="10 Days">10 Days</option>
                            <option value="14 Days">14 Days</option>
                            <option value="3 Weeks">3 Weeks</option>
                            <option value="4 Weeks">4 Weeks</option>
                            <option value="5 Weeks">5 Weeks</option>
                            <option value="6 Weeks">6 Weeks</option>
                            <option value="7 Weeks">7 Weeks</option>
                            <option value="8 Weeks">8 Weeks</option>
                        </select>
                    </div>
                    <div className="mb-3 form-group col-md-4">
                        <label className="form-label">Patient Preparation</label>
                        <input
                            name="specimenPatientPreparation"
                            className="form-control"
                            id="specimenPatientPreparation"
                            value={formData[keys].specimenPatientPreparation}
                            onChange={(e)=>onEdit(e, keys)}
                            placeholder="Overnight fasting period of 10-12hrs"
                        />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecimenEntry;
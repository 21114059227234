import React from "react";
import {formatDate} from "../../url";

export default function DrugForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Drug Name</label>
                                <input
                                    type="text"
                                    name="drugName"
                                    className="form-control"
                                    id="drugName"
                                    value={props.formData.drugName}
                                    onChange={props.onEdit}
                                    placeholder="Drug Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Category</label>
                                <select className="form-control" name="drugCategoryID" id="drugCategoryID"
                                        value={props.formData.drugCategoryID} onChange={props.onEdit}>
                                    <option value="">Select Drug Category</option>
                                    {
                                        props.drugCategoryList.length > 0 && props.drugCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.drugCategoryID}>{item.categoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Sub Category</label>
                                <select className="form-control" name="drugSubcategoryID" id="drugSubcategoryID"
                                        value={props.formData.drugSubcategoryID} onChange={props.onEdit}>
                                    <option value="">Select Sub Drug Category</option>
                                    {
                                        props.drugSubCategoryList.length > 0 && props.drugSubCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.drugSubcategoryID}>{item.subCategoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Sub Sub Category</label>
                                <select className="form-control" name="drugSubSubcategoryID" id="drugSubSubcategoryID"
                                        value={props.formData.drugSubSubcategoryID} onChange={props.onEdit}>
                                    <option value="">Select Sub Sub Drug Category</option>
                                    {
                                        props.drugSubSubCategoryList.length > 0 && props.drugSubSubCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.drugSubSubcategoryID}>{item.subSubCategoryName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Manufacturer</label>
                                <select className="form-control" name="manufacturerID" id="manufacturerID"
                                        value={props.formData.manufacturerID} onChange={props.onEdit}>
                                    <option value="">Select Manufacturer</option>
                                    {
                                        props.manufacturerList.length > 0 && props.manufacturerList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.manufacturerID}>{item.manufacturerName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Manufactured Date</label>
                                <input
                                    type="date"
                                    name="manufactureDate"
                                    className="form-control"
                                    id="manufactureDate"
                                    value={formatDate(props.formData.manufactureDate)}
                                    onChange={props.onEdit}
                                    placeholder="Manufactured Date"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Expiry Date</label>
                                <input
                                    type="date"
                                    name="expiryDate"
                                    className="form-control"
                                    id="expiryDate"
                                    value={formatDate(props.formData.expiryDate)}
                                    onChange={props.onEdit}
                                    placeholder="Expiry Date"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Drug Stock Price (₦)</label>
                                <input
                                    type="number"
                                    step="0.000000001"
                                    name="drugStockPrice"
                                    className="form-control"
                                    id="drugStockPrice"
                                    value={props.formData.drugStockPrice}
                                    onChange={props.onEdit}
                                    placeholder="Drug Stock Price"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Sales Price (₦)</label>
                                <input
                                    type="number"
                                    step="0.000000001"
                                    name="drugPrice"
                                    className="form-control"
                                    id="drugPrice"
                                    value={props.formData.drugPrice}
                                    onChange={props.onEdit}
                                    placeholder="Sales Price"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Drug Quantity</label>
                                <input
                                    type="number"
                                    name="drugQuantity"
                                    className="form-control"
                                    id="drugQuantity"
                                    value={props.formData.drugQuantity}
                                    onChange={props.onEdit}
                                    placeholder="Drug Quantity"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Pack Size</label>
                                <input
                                    type="text"
                                    name="packSize"
                                    className="form-control"
                                    id="packSize"
                                    value={props.formData.packSize}
                                    onChange={props.onEdit}
                                    placeholder="Pack Size"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Drug QRCode</label>
                                <input
                                    type="text"
                                    name="drugQRCode"
                                    className="form-control"
                                    id="drugQRCode"
                                    value={props.formData.drugQRCode}
                                    onChange={props.onEdit}
                                    placeholder="Drug QRCode"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Drug Row</label>
                                <input
                                    type="text"
                                    name="drugRow"
                                    className="form-control"
                                    id="drugRow"
                                    value={props.formData.drugRow}
                                    onChange={props.onEdit}
                                    placeholder="Drug Row"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    cols={3}
                                    rows={3}
                                    name="drugDescription"
                                    className="form-control"
                                    id="drugDescription"
                                    value={props.formData.drugDescription}
                                    onChange={props.onEdit}
                                    placeholder="Description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
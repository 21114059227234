import React from "react";

export default function ServiceForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">service Type</label>
                                <select className="form-control" name="serviceID" id="serviceID"
                                        value={props.formData.serviceID} onChange={props.onEdit}>
                                    <option value="">Select service Type</option>
                                    {
                                        props.serviceTypeList.length > 0 && props.serviceTypeList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.serviceID}>{item.serviceName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">service Item Name</label>
                                <input
                                    type="text"
                                    name="serviceItemName"
                                    className="form-control"
                                    id="serviceItemName"
                                    value={props.formData.serviceItemName}
                                    onChange={props.onEdit}
                                    placeholder="service Item Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Amount</label>
                                <input
                                    type="number"
                                    name="serviceAmount"
                                    className="form-control"
                                    step="0.01"
                                    id="serviceAmount"
                                    value={props.formData.serviceAmount}
                                    onChange={props.onEdit}
                                    placeholder="Service Amount"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Status</label>
                                <select className="form-control" name="serviceStatus" id="serviceStatus"
                                        value={props.formData.serviceStatus} onChange={props.onEdit}>
                                    <option value="">Select Service Status</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">In Active</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Service Description</label>
                                <textarea
                                    rows={3}
                                    cols={3}
                                    name="serviceDescription"
                                    className="form-control"
                                    id="serviceDescription"
                                    value={props.formData.serviceDescription}
                                    onChange={props.onEdit}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
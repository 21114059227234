import React from "react";

export default function BlockForm(props) {
  return (
    <>
      <div
        id={`${
          typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`}
        className="modal fade bs-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {props.title ?? ""}
              </h4>
              <button
                type="button"
                className={`${
                  typeof props.close !== "undefined"
                    ? props.close
                    : "closeModal"
                }`}
                id={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Block Name</label>
                  <input
                    type="text"
                    name="blockName"
                    className="form-control"
                    id="blockName"
                    value={props.blockFormData.blockName}
                    onChange={props.onEditBlock}
                    placeholder="Block Name"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Block Address</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    id="address"
                    value={props.blockFormData.address}
                    onChange={props.onEditBlock}
                    placeholder="Block Address"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Extension Number</label>
                  <input
                    type="text"
                    name="extensionNo"
                    className="form-control"
                    id="extensionNo"
                    value={props.blockFormData.extensionNo}
                    onChange={props.onEditBlock}
                    placeholder="Extension Number"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    value={props.blockFormData.phoneNumber}
                    onChange={props.onEditBlock}
                    placeholder="Phone Number"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Email Address</label>
                  <input
                    type="text"
                    name="emailAddress"
                    className="form-control"
                    id="emailAddress"
                    value={props.blockFormData.emailAddress}
                    onChange={props.onEditBlock}
                    placeholder="Email Address"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Latitude</label>
                  <input
                    type="text"
                    name="geoLat"
                    className="form-control"
                    id="geoLat"
                    value={props.blockFormData.geoLat}
                    onChange={props.onEditBlock}
                    placeholder="Latitude"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Longitude</label>
                  <input
                    type="text"
                    name="geoLong"
                    className="form-control"
                    id="geoLong"
                    value={props.blockFormData.geoLong}
                    onChange={props.onEditBlock}
                    placeholder="Longitude"
                  />
                </div>

                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Description</label>
                  <textarea
                    cols={3}
                    rows={3}
                    name="description"
                    className="form-control"
                    id="description"
                    value={props.blockFormData.description}
                    onChange={props.onEditBlock}
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {props.IsFormLoading ? (
                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    />{" "}
                    Please wait...
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={props.onSubmitBlock}
                  className="btn btn-danger waves-effect waves-light"
                >
                  {props.btn ?? "submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import PageTitle from "../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {serverLink, timeConvert} from "../url";
import {showAlert, showConfirm} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import Loader from "../common/loader";
import DoctorScheduleForm from "./schedule-form";

function DoctorSchedule(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [scheduleData, setScheduleData] = useState([])
    const [scheduleSlots, setScheduleSlots] = useState([])
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const [formData, setFormData] = useState({
        Monday: {},
        Tuesday: {},
        Wednesday: {},
        Thursday: {},
        Friday: {},
        Saturday: {},
        Sunday: {},
        doctorScheduleID: "",
        type: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}doctor/schedule/list/${formData.InsertedBy}`, token)
            .then((result) => {
                if (result.data.message === "success") {
                    if (result.data.data.length > 0){
                        setScheduleData(result.data.data);
                        setScheduleSlots(result.data.slots);

                    }else {
                        setScheduleData([])
                        setScheduleSlots([])
                    }
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const onEdit = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const type = formData.type;
        if (id.includes('Monday_')) {
            createScheduleData(e, 'Monday', type)
        }

        if (id.includes('Tuesday_')) {
            createScheduleData(e, 'Tuesday', type)
        }

        if (id.includes('Wednesday_')) {
            createScheduleData(e, 'Wednesday', type)
        }

        if (id.includes('Thursday_')) {
            createScheduleData(e, 'Thursday', type)
        }

        if (id.includes('Friday_')) {
            createScheduleData(e, 'Friday', type)
        }

        if (id.includes('Saturday_')) {
            createScheduleData(e, 'Saturday', type)
        }

        if (id.includes('Sunday_')) {
            createScheduleData(e, 'Sunday', type)
        }
    }

    const createScheduleData = (event, day, action) => {
        const id = event.target.id;
        const value = event.target.value;
        let startTime = ''; let endTime = '';

        if (id === day+'_duration') {
            setFormData({
                ...formData,
                [day]: {
                    ...formData[day],
                    dayName: day,
                    duration: value,
                    endTime: '',
                numberOfSlots: 0,
                }
            })
        }else if (id === day+'_startTime') {
            setFormData({
                ...formData,
                [day]: {
                    ...formData[day],
                    dayName: day,
                    startTime: value,
                }
            })
        }else {
            if (typeof formData[day].startTime === 'undefined' || typeof formData[day].startTime === ''){
                toast.error("Please select start time first");
                setFormData({
                    ...formData,
                    [day]: {
                        ...formData[day],
                        dayName: day,
                        endTime: '',
                        numberOfSlots: 0,
                    }
                })
                return false;
            }
            startTime = formData[day].startTime
            endTime = value
            const startTimeObj = new Date(`2000-01-01T${startTime}:00`);
            const endTimeObj = new Date(`2000-01-01T${endTime}:00`);
            if (startTimeObj >= endTimeObj) {
                toast.error("Start time cannot be greater than or equal to the end time");
                setFormData({
                    ...formData,
                    [day]: {
                        ...formData[day],
                        dayName: day,
                        endTime: ""
                    }
                })
                return false;
            }

            if (typeof formData[day].duration === 'undefined' || typeof formData[day].duration === ''){
                toast.error("Please select appointment duration first");
                return false;
            }else{
                let duration = formData[day].duration;
                let duration_interval = getTimeInterval(startTimeObj, endTimeObj);
                let duration_data_set = 0;

                if (duration.length === 1) {
                    duration_data_set = duration_interval / (parseInt(duration)*60)
                }
                else {
                    duration_data_set = duration_interval / parseInt(duration);
                }

                let slots = [];
                if(action === 'update') {
                    if(typeof formData[day].slots !== 'undefined') {
                        formData[day].slots.map((slot) => {
                            if(slot.status === 'booked') {
                                slots.push(slot)
                            }
                        })
                    }
                }

                let start_slot_time = formData[day].startTime;
                [...Array(Math.round(duration_data_set))].map((number, index) => {
                    let slot = "";
                    if(duration.length === 1)
                        slot = new Date(startTimeObj.getTime() + parseInt(duration)*60*(index+1)*60000);
                    else
                        slot = new Date(startTimeObj.getTime() + parseInt(duration)*(index+1)*60000);

                    let end_time_min_checker = slot.getMinutes() < 10 ? "0"+slot.getMinutes() : slot.getMinutes()
                    let end_slot_time = `${slot.getHours()}:${end_time_min_checker}`;
                    let slot_data = `${start_slot_time} - ${end_slot_time}`;
                    slots.push({slot: slot_data, status: 'available'});
                    start_slot_time = end_slot_time;
                })

                if (action === 'add'){
                    setFormData({
                        ...formData,
                        [day]: {
                            ...formData[day],
                            dayName: day,
                            endTime: value,
                            numberOfSlots: Math.round(duration_data_set),
                            slot: slots
                        }
                    })
                } else {
                    let updated_slots = slots.reduce((acc, current) => {
                        const x = acc.find(item => item.slot === current.slot);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, [])
                    setFormData({
                        ...formData,
                        [day]: {
                            ...formData[day],
                            dayName: day,
                            endTime: value,
                            numberOfSlots: Math.round(duration_data_set),
                            slot: updated_slots
                        }
                    })
                }
            }
        }


    }

  const getTimeInterval = (startTime, endTime) => {
        let diff =(startTime.getTime() - endTime.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
    }


    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
        }
    };
    const onMobileImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                mobileImage: event.target.files[0],
                ImageData2: event.target.files[0],
            });
        }
    };

    const onSubmit = async () => {
        let schedule_data  = [];
        days.map((item, index)=>{
            schedule_data.push({dayName: item, duration: formData[item].duration, startTime: formData[item].startTime, endTime: formData[item].endTime, numberOfSlots: formData[item].numberOfSlots ?? 0, slots: formData[item].slot ?? []})
        })
        let sendData = {
            schedule_data,
            submittedBy: formData.InsertedBy,
            type: formData.type,
        }
        setIsFormLoading(true);
        await axios
            .post(`${serverLink}doctor/schedule/add`, sendData, token)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Schedule Added Successfully");
                    setIsFormLoading(false);
                    getData();
                    document.getElementById("close").click();
                    setFormData({
                        ...formData,
                        Monday: {},
                        Tuesday: {},
                        Wednesday: {},
                        Thursday: {},
                        Friday: {},
                        Saturday: {},
                        Sunday: {},
                    });
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const getDaysSchedule = (schedule, day, attribute) => {
      if (schedule.length > 0){
          const filter = schedule.filter(e => e.dayName === day);
          if (filter.length > 0){
              return filter[0][attribute];
          }else{

          }
      }else {
          return  '';
      }
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Schedule"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Schedule</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                {
                                                    scheduleData.length > 0 ?
                                                        <button type="button" className="btn btn-primary"
                                                                data-toggle="modal" data-target="#responsive-modal"
                                                                onClick={() => {
                                                                    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                                                                    const structureData = {};
                                                                    daysOfWeek.forEach(day => {
                                                                        structureData[day] = {
                                                                            duration: getDaysSchedule(scheduleData, day, 'duration'),
                                                                            startTime: getDaysSchedule(scheduleData, day, 'startTime'),
                                                                            endTime: getDaysSchedule(scheduleData, day, 'endTime'),
                                                                            numberOfSlots: getDaysSchedule(scheduleData, day, 'numberOfSlots'),
                                                                            slot: scheduleSlots
                                                                                .filter(e => e.daysID === getDaysSchedule(scheduleData, day, 'daysID'))
                                                                                .map(x => ({ slot: x.slot, status: x.status })),
                                                                        };
                                                                    });

                                                                    setFormData({
                                                                        ...structureData,
                                                                        InsertedBy: formData.InsertedBy,
                                                                        type: 'update'
                                                                    });

                                                                }}>
                                                            <i className="fa fa-pencil-alt"></i> Update Schedule
                                                        </button>
                                                        : <button type="button" className="btn btn-info"
                                                                  data-toggle="modal" data-target="#responsive-modal"
                                                                  onClick={() => {
                                                                      setFormData({
                                                                          ...formData,
                                                                          Monday: {},
                                                                          Tuesday: {},
                                                                          Wednesday: {},
                                                                          Thursday: {},
                                                                          Friday: {},
                                                                          Saturday: {},
                                                                          Sunday: {},
                                                                          type: 'add'
                                                                      });
                                                                  }}>
                                                            <i className="fa fa-plus"></i> Add Schedule
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        scheduleData.length > 0 ?
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Days</th>
                                                        <th>Duration</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>No. Of Slots</th>
                                                        <th>Slots</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        days.map((item, index) => {
                                                            const schedule = scheduleData.filter(e => e.dayName === item);
                                                            const slots = scheduleSlots.filter(e => e.daysID === schedule[0]?.daysID);
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item}</td>
                                                                    <td>{schedule[0]?.duration}</td>
                                                                    <td>{timeConvert(schedule[0]?.startTime)}</td>
                                                                    <td>{timeConvert(schedule[0]?.endTime)}</td>
                                                                    <td>{schedule[0]?.numberOfSlots}</td>
                                                                    <td>
                                                                        {
                                                                            slots.length > 0 && slots.map((element, key) => {
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <span
                                                                                            className={element.status === 'available' ? `badge badge-info` : `badge badge-danger`}>{element.slot}</span><br/>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="alert alert-info">
                                                <button type="button" className="close" data-dismiss="alert"
                                                        aria-label="Close"><span aria-hidden="true">×</span></button>
                                                <h3 className="text-info"><i
                                                    className="fa fa-info"></i> No Schedule Found!</h3>
                                                It appears that your appointment schedule has not been created yet. To facilitate booking appointments, we kindly request you to add your schedule by clicking the "Add Schedule" button. <br/><br/>

                                                Adding your schedule will enable patients to book appointments with you conveniently, ensuring efficient management of your time and availability.
                                            </div>

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DoctorScheduleForm
                    title="Manage Doctor Schedule"
                    formData={formData}
                    scheduleData={scheduleData}
                    scheduleSlots={scheduleSlots}
                    days={days}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    onImageChange={onImageChange}
                    onMobileImageChange={onMobileImageChange}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(DoctorSchedule);
import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {setGeneralDetails} from "../../../action/actions";
import {Link} from "react-router-dom";

const PatientAppointmentEncounters = ({patientSerial, loginData, setOnGeneralDetails, serviceName, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [appointmentList, setAppointmentList] = useState([])
    const header = ["S/N", "Date", "Time", "Doctor",  "Department", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/appointment/list/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data);
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return appointmentList.length > 0 &&  appointmentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                        <td>{item.appointmentTime}</td>
                        <td>{item.doctorName}</td>
                        <td>{item.departmentName}</td>
                        <td>
                            <a href=""
                                  className={"btn btn-info btn-sm  mr-3"}
                                  onClick={()=>{
                                      setOnGeneralDetails(item);
                                  }}
                            >
                                View <i className="fa fa-arrow-alt-circle-right"></i>
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient {serviceName} Encounters</h5>
                    <DataTable tableID="patient-appointments-encounters" header={header} body={showTable()} title="Patient {serviceName} Encounters"/>
                </div>
            </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientAppointmentEncounters);


import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";

const PatientDependants = ({patientSerial, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [dependantList, setDependantList] = useState([])
    const header = ["S/N", "Dependant Name", "Dependant ID", "Phone Number",  "Email Address", "Last Visit Date"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}slider/all`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setDependantList(result.data);
                }else {
                    setDependantList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return dependantList.length > 0 &&  dependantList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.patientSerial}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{formatDateAndTime(item.lastVisit, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Dependant</h5>
                    <DataTable tableID="patient-dependant" header={header} body={showTable()} title="Patient Dependant Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientDependants);


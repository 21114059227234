import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {calculateAge, currencyConverter, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";

import Loader from "../../common/loader";
import MenuCard from "../../common/card/menu-card";
import PatientImagingRequest from "../../doctor/utils/patient-imaging-request";
import {PatientInfoCardLarge} from "../../patient/utils/patient-info-card-large";
import {toast} from "react-toastify";
import PrescriptionRequestForm from "../../doctor/components/prescription-request-form";
import PrescriptionEditForm from "./prescription-edit-form";
import MakePayment from "../../finance/choose-payment/choose-payment";
import PatientPrescriptionRequest from "../../doctor/utils/patient-prescription-request";
import PatientAllergies from "../../patient/utils/patient-allergies";
import PatientVitals from "../../patient/utils/patient-vitals";
import PatientDoctorNote from "../../doctor/utils/patient-doctor-note";

function PrescriptionDashboard(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(props.patientDetails)
    const [rebuildPrescription, setRebuildPrescription] = useState(null);
    const [rebuildDashboard, setRebuildDashboard] = useState(null);
    const [prescriptionItems, setPrescriptionItems] = useState([]);
    const [selectedServices, setSelectedServices] = useState([])
    const [checkedItems, setCheckedItems] = useState([])
    const [clickedItems, setClickedItems] = useState({})
    const [paidItems, setPaidItems] = useState(0)

    useEffect( () => {
        getData();
    }, [rebuildDashboard]);

    const getData = async () => {
        setIsLoading(true)
        let record = {};
        await axios.get(`${serverLink}patient/consultation/${patientDetails.patientSerial}`, token)
            .then(async (result) => {
                const patient = result.data;
                await axios
                    .get(`${serverLink}pharmacy/prescription/details/${patientDetails.drugPrescriptionID}`, token)
                    .then((res) => {
                        if (res.data.message === 'success') {
                            setPrescriptionItems(res.data.items)
                            setPaidItems(res.data.items.filter(x=>x.status === 'Paid'))
                            const item = {
                                ...patientDetails,
                                walletAmount:patient[0].walletAmount,
                                passport:patient[0].passport,
                                firstName:patient[0].firstName,
                                middleName:patient[0].middleName,
                                surname:patient[0].surname,
                                title:patient[0].title,
                                emailAddress:patient[0].emailAddress,
                                phoneNumber:patient[0].phoneNumber,
                                altPhoneNumber:patient[0].altPhoneNumber,
                                dateOfBirth:patient[0].dateOfBirth,
                                bloodGroup:patient[0].bloodGroup,
                                gender:patient[0].gender,
                                status:patient[0].status,
                                maritalStatus:patient[0].maritalStatus,
                                nextOfKinName:patient[0].nextOfKinName,
                                nextOfKinPhone:patient[0].nextOfKinPhone,
                                nextOfKinRelationship:patient[0].nextOfKinRelationship,
                                residentialAddress:patient[0].residentialAddress,
                                lastVisit:patient[0].lastVisit,
                                ...res.data.prescription[0]
                            }
                            setPatientDetails(item)
                        }else{
                            setPrescriptionItems([])
                            setPaidItems(0)
                        }
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log("NETWORK ERROR");
                    });
            })
            .catch((err) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    const initiatePayment = async (paymentData) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...paymentData,
            prescriptionAmount: patientDetails.prescriptionAmount,
            amountPaid: checkedItems.reduce((acc, service) => acc + service.drugTotalAmount, 0),
            totalItemsPaid: checkedItems.reduce((acc, service) => acc + service.drugTotalAmount, 0) + paidItems.reduce((acc, service) => acc + service.drugTotalAmount, 0),
            cartItems: checkedItems,
            drugPrescriptionID: patientDetails.drugPrescriptionID,
            itemID: patientDetails.drugPrescriptionID,
            submittedBy: props.loginData[0]?.userID,
        }

        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then(async (res) => {
            if (res.data.message === "success") {
                await axios.patch(`${serverLink}pharmacy/prescription/payment/update`, sendData, token).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Payment Successfully");
                        setIsFormLoading(false);
                        setRebuildDashboard(generate_token(5))
                        setCheckedItems([])
                        setRebuildPrescription(generate_token(5))
                        document.getElementById("closePayment").click();
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong updating payment. Please try again!");
                    }
                })
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const clearPayment = () => {
        if (checkedItems.reduce((acc, service) => acc + service.drugTotalAmount, 0) === 0){
            toast.error('Please select at lease one item before submitting')
            return false
        }
        document.getElementById('pay-now').click();
    }

    const showStatus = (status) => {
        switch (status) {
            case 'Pending':
                return <span className="badge badge-pill badge-info">{status}</span>;
            case 'Result Entered':
                return <span className="badge badge-pill badge-primary">{status}</span>;
            case 'Cancelled':
                return <span className="badge badge-pill badge-danger">{status}</span>;
            default:
                return <span className="badge badge-pill badge-secondary">{status}</span>;
        }
    };


    const checkAll = async () => {
        let checkBox = await document.getElementsByClassName('checkItem');

        for (let i = 0; i < checkBox.length ; i++) {
            const items = JSON.parse(checkBox[i].getAttribute("data"));
            if (!checkBox[i].checked) {
                checkBox[i].checked = true;
                setCheckedItems(prevServices => {
                    const filtered = prevServices.filter(service => service.prescriptionID !== items.prescriptionID);
                    return [...filtered, {...items, status: 'Paid'}];
                });
            }else{
                checkBox[i].checked = false;
                setCheckedItems(prevServices => {
                    return prevServices.filter(service => service.prescriptionID !== items.prescriptionID);
                });
            }

        }
    }

    const onCheck = (e) => {
        const items = JSON.parse(e.target.getAttribute("data"));
        const isChecked = e.target.checked;

        if (isChecked) {
            setCheckedItems(prevServices => {
                const filtered = prevServices.filter(service => service.prescriptionID !== items.prescriptionID);
                return [...filtered, {...items, status: 'Paid'}];
            });
        } else {
            setCheckedItems(prevServices => {
                return prevServices.filter(service => service.prescriptionID !== items.prescriptionID);
            });
        }
    };



    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Prescription Dashboard"]}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="card">
                                <ul className="nav nav-pills custom-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-timeline-tab" data-toggle="pill"
                                           href="#current-month" role="tab" aria-controls="pills-timeline"
                                           aria-selected="true">Prescription Dashboard</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-doctor-note-tab" data-toggle="pill"
                                           href="#doctor-note"
                                           role="tab" aria-controls="pills-doctor-note"
                                           aria-selected="false">Visit Note</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-allergies-tab" data-toggle="pill"
                                           href="#allergies" role="tab" aria-controls="pills-allergies"
                                           aria-selected="false">Allergies</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-vital-tab" data-toggle="pill" href="#vital"
                                           role="tab" aria-controls="pills-vital" aria-selected="false">Vitals Report</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-imaging-tab" data-toggle="pill"
                                           href="#imaging" role="tab" aria-controls="pills-imaging"
                                           aria-selected="false">Prescription History</a>
                                    </li>
                                </ul>
                                <hr/>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="current-month" role="tabpanel"
                                         aria-labelledby="pills-timeline-tab">
                                        <div className="card-body" style={{backgroundColor: '#eeeeee'}}>
                                            <div className="row">
                                                <PatientInfoCardLarge patientDetails={patientDetails}/>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h4><b>Prescription Request Details</b></h4>
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <tbody>
                                                                    <tr>
                                                                        <th>Requested By: </th>
                                                                        <td>{patientDetails.doctorName} => ({patientDetails.doctorID})</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Requested Date: </th>
                                                                        <td>{formatDateAndTime(patientDetails.submittedDate, 'date_and_time')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Amount Due: </th>
                                                                        <td>{currencyConverter(patientDetails.prescriptionAmount)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Amount Paid: </th>
                                                                        <td>{currencyConverter(patientDetails.amountPaid)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Balance: </th>
                                                                        <td>{currencyConverter(patientDetails.prescriptionAmount - patientDetails.amountPaid)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Payment Status: </th>
                                                                        <td>{patientDetails.paymentStatus === 'Paid' ? <span className="badge badge-pill badge-success">{patientDetails.paymentStatus}</span> : <span className="badge badge-pill badge-danger">{patientDetails.paymentStatus}</span>}</td>
                                                                    </tr>
                                                                    {
                                                                        !patientDetails.receivedBy ?
                                                                            <>
                                                                                <tr>
                                                                                    <th  style={{padding: '35px'}}>&nbsp; &nbsp;</th>
                                                                                    <td>&nbsp; &nbsp;</td>
                                                                                </tr>
                                                                            </>
                                                                            :
                                                                            <tr style={{borderTop: '1px solid #cccccc'}}>
                                                                                <td colSpan={2}>
                                                                                    <div className="col-md-12 pt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-md-6">
                                                                                                <span className="font-bold p-t-30 db">Payment Received By</span>
                                                                                                <h6>{patientDetails.receivedByName}</h6>
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                <span className="font-bold p-t-30 db">Received Date</span>
                                                                                                <h6>{formatDateAndTime(patientDetails.paymentDate, 'date_and_time')}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr style={{margin: '0px', padding: '0px'}}/>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-12" style={{marginTop: '100px'}}>
                                                <table className="table table-striped ">
                                                    <thead>
                                                    <tr className="bg-dark text-white">
                                                        <th>Drug Name</th>
                                                        <th>Drug Dose</th>
                                                        <th>Dose Frequency</th>
                                                        <th>Prescription</th>
                                                        <th>Quantity</th>
                                                        <th>Unit Price</th>
                                                        <th>Total</th>
                                                        <th>Status</th>
                                                        <th>Edit</th>
                                                        <th><input type="checkbox"
                                                                   id="checkAll"
                                                                   className="checkAll"
                                                                   onChange={()=>checkAll()}/></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        prescriptionItems.map((item, index)=>{
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{item.drugName}</td>
                                                                    <td>{item.drugDose}</td>
                                                                    <td>{item.doseFrequency}</td>
                                                                    <td>{item.prescription}</td>
                                                                    <td>{item.drugQuantity}</td>
                                                                    <td>{currencyConverter(item.drugAmount)}</td>
                                                                    <td>{currencyConverter(item.drugTotalAmount)}</td>
                                                                    <td>{item.status === 'Paid' ? <span className="badge badge-pill badge-success">{item.status}</span> : <span className="badge badge-pill badge-danger">{item.status}</span>}</td>
                                                                    <td> {
                                                                        item.status === 'Paid' || item.status === 'Cancelled' ? <b className="text-center">--</b> :
                                                                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#PrescriptionRequestFormModal"
                                                                               onClick={() => {
                                                                                   setClickedItems({
                                                                                       prescriptionID: item.prescriptionID,
                                                                                       drugID: item.drugID,
                                                                                       drugName: item.drugName,
                                                                                       drugDose: item.drugDose,
                                                                                       prescription: item.prescription,
                                                                                       status: item.status,
                                                                                       drugAmount: parseFloat(item.drugAmount),
                                                                                       drugTotalAmount: parseFloat(item.drugTotalAmount),
                                                                                       drugQuantity: parseInt(item.drugQuantity),
                                                                                   })
                                                                               }}>
                                                                                <i className="fa fa-edit"></i>
                                                                            </a>
                                                                    }</td>
                                                                    <td>
                                                                        {
                                                                            item.status === 'Paid' || item.status === 'Cancelled' ? <>--</> :
                                                                                <input type="checkbox"
                                                                                       id="checkItem"
                                                                                       data_id={item.prescriptionID}
                                                                                       data={JSON.stringify(item)}
                                                                                       className="checkItem"
                                                                                       name="checkItem"
                                                                                       value={item.prescriptionID}
                                                                                       onChange={onCheck} />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    </tbody>
                                                </table>


                                                {
                                                    checkedItems .length > 0 ?
                                                        <div>
                                                            <div className="col-md-12 mb-3 mt-5" style={{borderBottom: '2px solid #CCCCCC',borderTop: '2px solid #CCCCCC', marginBottom: '30px'}}>
                                                                <h3 style={{color: '#5687b7', fontWeight: 'bold'}}>SELECTED ITEMS</h3>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="card card-body printableArea">
                                                                    <h3><b>INVOICE</b> <span className="pull-right">#{generate_token(5)}</span></h3>
                                                                    <hr/>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="table-responsive m-t-40" style={{clear: 'both'}}>
                                                                                <table className="table table-hover">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th className="text-center">#</th>
                                                                                        <th>Description</th>
                                                                                        <th className="text-right">Quantity</th>
                                                                                        <th className="text-right">Unit Cost</th>
                                                                                        <th className="text-right">Total</th>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {
                                                                                        checkedItems.map((item, index)=>{
                                                                                            return(
                                                                                                <tr className="bg-white" key={index}>
                                                                                                    <td className="text-center">{index+1}</td>
                                                                                                    <td>{item.drugName}</td>
                                                                                                    <td className="text-right">{item.drugQuantity}</td>
                                                                                                    <td className="text-right">{currencyConverter(item.drugAmount)}</td>
                                                                                                    <td className="text-right">{currencyConverter(item.drugTotalAmount)}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12" style={{marginTop: '40px'}}>
                                                                            <div className="pull-right m-t-30 text-right">
                                                                                <hr/>
                                                                                <h3><b>Total :</b> {currencyConverter(checkedItems.reduce((acc, service) => acc + service.drugTotalAmount, 0))}</h3>
                                                                            </div>
                                                                            <div className="clearfix"></div>
                                                                            <hr/>
                                                                            <div className="text-right">
                                                                                <button id="print" onClick={clearPayment} className="btn btn-default btn-outline" type="button"> <span> Proceed to payment</span> </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : <></>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="imaging" role="tabpanel" aria-labelledby="pills-imaging-tab">
                                        <div className="card-body">
                                            <PatientPrescriptionRequest patientSerial={patientDetails.patientSerial} handleRemount={rebuildPrescription}/>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="doctor-note" role="tabpanel"
                                         aria-labelledby="pills-doctor-note-tab">
                                        <div className="card-body">
                                            <PatientDoctorNote appointmentID={patientDetails.appointmentID} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="allergies" role="tabpanel"
                                         aria-labelledby="pills-allergies-tab">
                                        <div className="card-body">
                                            <PatientAllergies patientSerial={patientDetails.patientSerial} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="vital" role="tabpanel"
                                         aria-labelledby="pills-vital-tab">
                                        <div className="card-body">
                                            <PatientVitals patientSerial={patientDetails.patientSerial} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open</button>
                <MakePayment
                    id="payment-modal"
                    title="Make Payment"
                    close="closePayment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    paymentFor="Pharmacy"
                    itemName={"Prescription"}
                    itemAmount={checkedItems.reduce((acc, service) => acc + service.drugTotalAmount, 0)}
                    amountDue={patientDetails.prescriptionAmount}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={initiatePayment}
                    onError={()=>{console.log("Error")}}
                />
                <PrescriptionEditForm
                    id="PrescriptionRequestFormModal"
                    title="Prescription Form"
                    close="closePrescriptionRequestFormModal"
                    clickedItems={clickedItems}
                    setSelectedServices={setSelectedServices}
                    selectedServices={selectedServices}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        patientDetails: state.GeneralDetails,
    };
};
export default connect(mapStateToProps, null)(PrescriptionDashboard);


import React, {useEffect, useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function OnlineConsultation(props) {
    const { patientSerial, patientName, walletAmount, appointmentID, handleRemount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [appointmentList, setAppointmentList] = useState([])

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        appointmentOnlineLinkID: '',
        appointmentID: appointmentID,
        consultationLink: '',
        consultationDate: '',
        consultationTime: '',
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect(() => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/appointment/consultation/online/${patientSerial}/${appointmentID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setAppointmentList(result.data.data);
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const onCaptureVitals = async () => {
        if (formData.consultationLink.toString().trim() === ''){
            toast.error('Please enter consultation Link')
            return false
        }

        if (formData.consultationDate.toString().trim() === ''){
            toast.error('Please select consultation date')
            return false
        }

        if (formData.consultationTime.toString().trim() === ''){
            toast.error('Please select consultation time')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
            await axios.post(`${serverLink}patient/appointment/consultation/online/add`, formData, token).then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Online Consultation Added Successfully");
                    setIsFormLoading(false);
                    props.setRebuildLink(generate_token(5));
                    document.getElementById("closeOnline").click();
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    toast.error("Something went wrong adding appointment online link. Please try again!");
                }
            }) .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Consultation Link</label>
                                <input
                                    type="text"
                                    id="consultationLink"
                                    name="consultationLink"
                                    className="form-control"
                                    value={formData.consultationLink}
                                    onChange={onEdit}
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Consultation Date</label>
                                <div className="input-group">
                                    <input
                                        type="date"
                                        required
                                        className="form-control"
                                        id="consultationDate"
                                        name="consultationDate"
                                        value={formData.consultationDate}
                                        onChange={onEdit}
                                    />
                                    <div className="input-group-append">
                                        <input
                                            type="time"
                                            required
                                            className="form-control"
                                            id="consultationTime"
                                            name="consultationTime"
                                            value={formData.consultationTime}
                                            onChange={onEdit}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={onCaptureVitals}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(OnlineConsultation);
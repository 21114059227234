import {calculateAge, currencyConverter, formatDateAndTime, imageExists, serverLink} from "../../url";
import React from "react";

export const PatientInfoCard = ({patientDetails}) => {
    const imagePath = `${serverLink}public/uploads/patient_uploads/${patientDetails.passport}`;
    const placeholderImage = require('../../img/male_avater.jpg');
    return (
        <div className="col-md-4">
            <div className="card">
                <div className="card-body">
                    <center className="m-t-30">
                        <img
                            src={imageExists(imagePath) ? imagePath : placeholderImage}
                            alt="passport"
                            className="rounded-circle border"
                            width="150"
                            height="150"
                        />
                        <h4 className="card-title m-t-10">{patientDetails.firstName} {patientDetails.middleName} {patientDetails.surname}</h4>
                        <h5 className="card-subtitle font-weight-bold text-dark">{patientDetails.patientSerial}</h5>
                        <h4 className="card-subtitle font-weight-bold text-danger">WALLET
                            AMOUNT: {currencyConverter(patientDetails.walletAmount)}</h4>
                        <h5 className="card-subtitle"><span
                            className="badge badge-success">{patientDetails.status}</span></h5>
                    </center>
                </div>
                <div>
                    <hr style={{margin: '0px', padding: '0px'}}/>
                </div>
                <div className="card-body">
                    <small className="text-muted">Gender</small>
                    <h6>{patientDetails.gender}</h6>
                    <small className="text-muted">Date of Birth </small>
                    <h6>{formatDateAndTime(patientDetails.dateOfBirth, 'date')} -
                        ({calculateAge(patientDetails.dateOfBirth)} Years Old)</h6>
                    <small className="text-muted">Blood Group</small>
                    <h6>{patientDetails.bloodGroup ?? "N/A"}</h6>
                    <small className="text-muted">Marital Status</small>
                    <h6>{patientDetails.maritalStatus ?? "N/A"}</h6>
                    <small className="text-muted">Email address </small>
                    <h6>{patientDetails.emailAddress ?? "N/A"}</h6>
                    <small className="text-muted p-t-30 db">Phone</small>
                    <h6>{patientDetails.phoneNumber} | {patientDetails.altPhoneNumber ?? "N/A"}</h6>
                    <small className="text-muted p-t-30 db">Address</small>
                    <h6>{patientDetails.residentialAddress ?? "N/A"}</h6>
                    <hr/>
                    <h4><b>Next of Kin</b></h4>
                    <hr/>
                    <div className="row">
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">Next of Kin</small>
                            <h6>{patientDetails.nextOfKinName ?? "N/A"}</h6>
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">Next of Kin Phone Number</small>
                            <h6>{patientDetails.nextOfKinPhone ?? "N/A"}</h6>
                        </div>
                        <div className="col-md-4">
                            <small className="text-muted p-t-30 db">Relationship</small>
                            <h6>{patientDetails.nextOfKinRelationship ?? "N/A"}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react";

const PageTitle = (props) => {

    return (
        <>
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-12 col-lg-5 align-self-center">
                        <h4 className="page-title">{props.title[1]}</h4>
                    </div>
                    <div className="col-7 align-self-center hide-mobile">
                        <div className="d-flex align-items-center justify-content-end">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{props.title[1]}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageTitle;
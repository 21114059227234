import React from "react";
export default function DoctorScheduleForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Days</th>
                                        <th>Duration</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>No. Of Slots</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        props.days.map((item, index)=>{
                                            // const schedule = props.scheduleData.filter(e=>e.dayName === item);
                                            const schedule = props.formData;
                                            const slots = props.scheduleSlots.filter(e=>e.daysID === schedule[item]?.daysID);
                                            return(
                                                <tr key={index}>
                                                    <td><input type="text" className="form-control" value={item} id={`${item}_days`} name="days" disabled readOnly/></td>
                                                    <td>
                                                        <select className="form-control" value={schedule[item]?.duration} onChange={props.onEdit} id={`${item}_duration`} name="duration">
                                                            <option value="">Select Duration</option>
                                                            <option value="15">15 Minutes</option>
                                                            <option value="30">30 Minutes</option>
                                                            <option value="1">1 Hours</option>
                                                            <option value="2">2 Hours</option>
                                                        </select>
                                                    </td>
                                                    <td><input type="time" value={schedule[item]?.startTime} onChange={props.onEdit} className="form-control" id={`${item}_startTime`} name="startTime"/></td>
                                                    <td><input type="time" value={schedule[item]?.endTime} onChange={props.onEdit} className="form-control" id={`${item}_endTime`} name="endTime"/></td>
                                                    <td><span className="badge badge-info">{schedule[item]?.numberOfSlots ?? 0}</span></td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
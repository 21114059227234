import React, {useEffect, useState} from "react";

// import the ECT package and style
import * as ECT from "@whoicd/icd11ect";
import "@whoicd/icd11ect/style.css";
import {connect} from "react-redux";
import axios from "axios";
import {generate_token, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";

function ECTReactComponent ({patientSerial, patientName, serviceID, serviceName, id, close, title, setRebuildDiagnosis, loginDetails}) {
    const iNo = 1;
    const [IsFormLoading, setIsFormLoading] = useState(false)

    const configuration = () => {
        // configure the ECT
        const settings = {
            // the API located at the URL below should be used only for software development and testing
            apiServerUrl: "https://icd11restapi-developer-test.azurewebsites.net",
            autoBind: false // in React we recommend using the manual binding
        };
        const callbacks = {
            selectedEntityFunction: (selectedEntity) => {
                // shows an alert with the code selected by the user and then clears the search results
                handleSubmit(selectedEntity)
                //ECT.Handler.clear(this.iNo);
            }
        };
        ECT.Handler.configure(settings, callbacks);
    }

    const handleSubmit = async (selectedItem) => {
        const sendData = {
            patientSerial: patientSerial,
            patientName: patientName,
            requestID: serviceID,
            requestType: serviceName,
            doctorID:  `${loginDetails[0]?.userID}`,
            doctorName: `${loginDetails[0]?.firstName} ${loginDetails[0]?.middleName} ${loginDetails[0]?.surname}`,
            icdCode: selectedItem.code,
            icdTitle: selectedItem.title,
            submittedBy: `${loginDetails[0]?.userID}`,
        }

        showConfirm(
            "CONFIRM",
            `Are you sure you want to add the ${sendData.icdTitle}: ${sendData.icdCode}?`,
            "warning"
        ).then( async (IsConfirmed) => {
            if (IsConfirmed)  {
                await axios.post(`${serverLink}icd/add`, sendData, loginDetails[0]?.token)
                    .then( res => {
                        if (res.data.message === 'success'){
                            showAlert("ADDED", "Code added successfully", "success");
                            setRebuildDiagnosis(generate_token(5));
                            setIsFormLoading(false)
                        } else {
                            showAlert("ERROR", "Something went wrong adding code. Please try again", "error");
                        }
                    })
                    .catch(err => {
                        showAlert("ERROR", "Something went wrong adding code. Please try again", "error");
                    })
            } else {
                showAlert("CANCELLED", "Cancelled Successfully", "error");
            }
        });
    }

    useEffect(() => {
        configuration();
        ECT.Handler.bind(iNo);
    }, [])

    return (
        <div id={`${
            typeof id !== "undefined" ? id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof close !== "undefined" ? close : "closeModal"
                        }`}  id={`${
                            typeof close !== "undefined" ? close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                Type for starting search:
                                {/* input element used for typing the search */}
                                <input
                                    type="text"
                                    className="ctw-input form-control"
                                    autoComplete="off"
                                    data-ctw-ino={iNo}
                                />
                            </div>
                            <div className="col-12">
                                {/* div element used for showing the search results */}
                                <div className="ctw-window" data-ctw-ino={iNo} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginDetails: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ECTReactComponent);
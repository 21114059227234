import React from "react";
export default function LabForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Laboratory Name</label>
                                <input
                                    name="laboratoryName"
                                    className="form-control"
                                    id="laboratoryName"
                                    value={props.formData.laboratoryName}
                                    onChange={props.onEdit}
                                    placeholder="Laboratory Name"
                                />

                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Laboratory Type</label>
                                <input
                                    type="text"
                                    name="labType"
                                    className="form-control"
                                    id="labType"
                                    value={props.formData.labType}
                                    onChange={props.onEdit}
                                    placeholder="Laboratory Type"
                                />

                            </div>
                            <div className="mb-3 col-md-6 form-group">
                                <label className="form-label">Block </label>
                                <select className="form-control" name="blockID" id="blockID"
                                        value={props.formData.blockID} onChange={props.onEdit}>
                                    <option value="">Select Block</option>
                                    {
                                        props.blockList.length > 0 && props.blockList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.hospitalBlockID}>{item.blockName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Laboratory Address</label>
                                <input
                                    type="text"
                                    name="laboratoryAddress"
                                    className="form-control"
                                    id="laboratoryAddress"
                                    value={props.formData.laboratoryAddress}
                                    onChange={props.onEdit}
                                    placeholder="Laboratory Address"
                                />

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React, { useState} from "react";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import './style.css';
function ImageUpload(props) {
    const { maxSize, setImagePath, setImageDisplay} = props;
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const InitialValue = {
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(InitialValue)

    const kilobyteToByte = (bytes) => {
        return bytes * 1024;
    }
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
        } else {
            toast.error("Only .png, .jpg and .jpeg format allowed!")
            return false;
        }
        if (file.size > kilobyteToByte(maxSize)) {
            toast.error(`max file size is ${maxSize}KB`)
            return false;
        }

        reader.onload = () => {
            setFormData({
                ...formData,
                ImagePath: file,
                ImageName: file.name,
                ImageDisplay: reader.result,
            })
            setSelectedPhoto(reader.result);
            setImagePath(file);
            setImageDisplay(reader.result);
        };
        reader.readAsDataURL(file);
    };

    return <div className="passport-upload-box">
        <input
            type="file"
            accept="image/*"
            onChange={handlePhotoChange}
            id="upload-photo"
            style={{ display: 'none' }}
        />
        <label htmlFor="upload-photo"  style={{width: '100%'}}>
            <div className="upload-box">
                <span>Choose Photo</span>
                {selectedPhoto && <img src={selectedPhoto} alt="Selected" />}
            </div>
        </label>
        <span className="alert-danger"> Max of {maxSize}KB file is allowed!</span>
    </div>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(ImageUpload);
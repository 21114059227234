import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import {formatDateAndTime, serverLink} from "../../url";
import axios from "axios";
import {connect} from "react-redux";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import MenuForm from "./menu-form";
import SubMenuForm from "./sub-menu-form";
import Loader from "../../common/loader";


function MenuSettings(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [menuList, setMenuList] = useState([])
    const [subMenuList, setSubMenuList] = useState([])
    const header = ["S/N", "Menu Name",  "Menu Icon", "Updated By", "Updated Date", "Action"];
    const header3 = ["S/N", "Menu Name","Sub Menu Name",  "Menu Link", "Visibility", "Updated By", "Updated Date",  "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        MenuName: "",
        Icon: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })
    const [formData2, setFormData2] = useState({
        EntryID: "",
        SubMenuName: "",
        MainMenuID: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/report/menu/data`)
            .then((result) => {
                setMenuList(result.data.menu);
                setSubMenuList(result.data.subMenu);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return menuList.length > 0 && menuList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.MenuName}</td>
                        <td>{item.Icon}</td>
                        <td>{item.InsertedBy}</td>
                        <td>{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       MenuName: item.MenuName,
                                       Icon: item.Icon,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable3 = () => {
        try {
            return subMenuList.length > 0 &&  subMenuList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.MenuName}</td>
                        <td>{item.SubMenuName}</td>
                        <td>{item.SubMenuLink}</td>
                        <td>{item.Visibility?.toString() ==="1" ? "Show" : "Hide"}</td>
                        <td>{item.InsertedBy}</td>
                        <td>{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#sub-menu"
                               onClick={() => {
                                   setFormData2({
                                       ...formData2,
                                       EntryID: item.EntryID,
                                       SubMenuName: item.SubMenuName,
                                       SubMenuLink: item.SubMenuLink,
                                       MainMenuID: item.MainMenuID,
                                       Visibility: item.Visibility,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit2 = (e) => {
        setFormData2({
            ...formData2,
            [e.target.id]: e.target.value,
        });
    }


    const onSubmit = async () => {
        if (formData.MenuName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter menu name", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/report/settings/menu/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Main Menu Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            MenuName: "",
                            Icon: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/report/settings/menu/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Main Menu Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            MenuName: "",
                            Icon: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit2 = async () => {
        if (formData2.MainMenuID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select main menu", "error");
            return false;
        }

        if (formData2.SubMenuName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub menu name", "error");
            return false;
        }

        if (formData2.SubMenuLink.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub sub menu link", "error");
            return false;
        }

        if (formData2.Visibility.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select visibility", "error");
            return false;
        }

        if (formData2.EntryID === "") {
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/report/settings/sub-menu/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Menu Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData2({
                            ...formData2,
                            EntryID: "",
                            SubMenuName: "",
                            SubMenuLink: "",
                            Visibility: "",
                            MainMenuID: "",
                        });

                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/report/settings/sub-menu/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Menu Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData2({
                            ...formData2,
                            EntryID: "",
                            SubMenuName: "",
                            SubMenuLink: "",
                            Visibility: "",
                            MainMenuID: "",
                        });
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return(IsLoading ? <Loader/> :
        <>
            <PageTitle title={["Home", "Menu Settings"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ul className="nav nav-tabs customtab" role="tablist">
                                    <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#home2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cubes text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Main Menu</span></a> </li>
                                    <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#profile2" role="tab"><span className="hidden-sm-up"><i className="fa fa-cubes-stacked text-secondary"></i></span> <span className="hidden-xs-down text-secondary">Sub Menu</span></a> </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home2" role="tabpanel">
                                        <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                            <h4 className="card-title">Main Menu</h4>
                                            <div className="ml-auto">
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={()=>{
                                                        setFormData({
                                                            ...formData,
                                                            EntryID: "",
                                                            MenuName: "",
                                                            Icon: "",
                                                        });
                                                    }}>
                                                        <i className="fa fa-plus"></i> Add Main Menu
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <DataTable tableID="menu" header={header} body={showTable()} title="Main Menu List" />
                                    </div>

                                    <div className="tab-pane  p-10" id="profile2" role="tabpanel">
                                        <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                            <h4 className="card-title">Sub Menu</h4>
                                            <div className="ml-auto">
                                                <div className="btn-group">
                                                    <button type="button" className="btn btn-info" data-toggle="modal"
                                                            data-target="#sub-menu" onClick={() => {
                                                        setFormData2({
                                                            ...formData2,
                                                            EntryID: "",
                                                            SubMenuName: "",
                                                            SubMenuLink: "",
                                                            Visibility: "",
                                                            MainMenuID: "",
                                                        });
                                                    }}>
                                                        <i className="fa fa-plus"></i> Add Sub Menu
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <DataTable  tableID="sub-sub-menu"  header={header3} body={showTable3()} title="Sub Menu List" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MenuForm
                title="Manage Main Menu"
                formData={formData}
                onEdit={onEdit}
                onSubmit={onSubmit}
                IsFormLoading={IsFormLoading}
                setFormData={setFormData}
                modalID="modal-report"
            />
            <SubMenuForm
                id="sub-menu"
                close="closeModal"
                title="Manage Sub Menu"
                formData={formData2}
                menuList={menuList}
                onEdit={onEdit2}
                onSubmit={onSubmit2}
                IsFormLoading={IsFormLoading2}
                setFormData={setFormData2}
                modalID="modal-report2"
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(MenuSettings);


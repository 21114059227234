import React from "react";

export default function ManufacturerForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Drug manufacturer Name</label>
                                <input
                                    type="text"
                                    name="manufacturerName"
                                    className="form-control"
                                    id="manufacturerName"
                                    value={props.formData.manufacturerName}
                                    onChange={props.onEdit}
                                    placeholder="ManufacturerName"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    name="phoneNumber"
                                    className="form-control"
                                    id="phoneNumber"
                                    value={props.formData.phoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="Phone Number"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    name="emailAddress"
                                    className="form-control"
                                    id="emailAddress"
                                    value={props.formData.emailAddress}
                                    onChange={props.onEdit}
                                    placeholder="Email Address"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Address</label>
                                <input
                                    type="text"
                                    name="address"
                                    className="form-control"
                                    id="address"
                                    value={props.formData.address}
                                    onChange={props.onEdit}
                                    placeholder="Office Address"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Description</label>
                                <textarea
                                    cols={3}
                                    rows={3}
                                    name="description"
                                    className="form-control"
                                    id="description"
                                    value={props.formData.description}
                                    onChange={props.onEdit}
                                    placeholder="Description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
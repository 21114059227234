import React from "react";

export default function RoomForm(props) {
  return (
    <>
      <div
        id={`${
          typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`}
        className="modal fade bs-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {props.title ?? ""}
              </h4>
              <button
                type="button"
                className={`${
                  typeof props.close !== "undefined"
                    ? props.close
                    : "closeModal"
                }`}
                id={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Room Name</label>
                  <input
                    type="text"
                    name="roomName"
                    className="form-control"
                    id="roomName"
                    value={props.roomFormData.roomName}
                    onChange={props.onEditRoom}
                    placeholder="Room Name"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Email Address</label>
                  <input
                    type="text"
                    name="emailAddress"
                    className="form-control"
                    id="emailAddress"
                    value={props.roomFormData.emailAddress}
                    onChange={props.onEditRoom}
                    placeholder="Email Address"
                  />
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    value={props.roomFormData.phoneNumber}
                    onChange={props.onEditRoom}
                    placeholder="Phone Number"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Bed Capacity</label>
                  <input
                    type="text"
                    name="bedCapacity"
                    className="form-control"
                    id="bedCapacity"
                    value={props.roomFormData.bedCapacity}
                    onChange={props.onEditRoom}
                    placeholder="Bed Capacity"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Number of Bed Occupied</label>
                  <input
                    type="text"
                    name="bedOccupied"
                    className="form-control"
                    id="bedOccupied"
                    value={props.roomFormData.bedOccupied}
                    onChange={props.onEditRoom}
                    placeholder="Number of Bed Occupied"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Room Amount (₦)</label>
                  <input
                    type="number"
                    step="0.000000001"
                    name="roomAmount"
                    className="form-control"
                    id="roomAmount"
                    value={props.roomFormData.roomAmount}
                    onChange={props.onEditRoom}
                    placeholder="Room Amount"
                  />
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Block</label>
                  <select
                    className="form-control"
                    name="blockID"
                    id="blockID"
                    value={props.roomFormData.blockID}
                    onChange={props.onEditRoom}
                  >
                    <option value="">Select Block</option>
                    {props.blockList.length > 0 &&
                      props.blockList.map((item, index) => {
                        return (
                          <option key={index} value={item.hospitalBlockID}>
                            {item.blockName}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Room Status</label>
                  <select
                    className="form-control"
                    name="status"
                    id="status"
                    value={props.roomFormData.status}
                    onChange={props.onEditRoom}
                  >
                    <option value="">Select Room Status</option>
                    <option value="Normal">Normal</option>
                    <option value="Maintenance">Maintenance</option>
                  </select>
                </div>

                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Room Type</label>
                  <select
                    className="form-control"
                    name="typeID"
                    id="typeID"
                    value={props.roomFormData.typeID}
                    onChange={props.onEditRoom}
                  >
                    <option value="">Select Room Type</option>
                    {props.roomTypeList.length > 0 &&
                      props.roomTypeList.map((item, index) => {
                        return (
                          <option key={index} value={item.hospitalRoomTypeID}>
                            {item.typeName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="mb-3 form-group col-md-6">
                  <label className="form-label">Room Manager</label>
                  <select
                    className="form-control"
                    name="roomManager"
                    id="roomManager"
                    value={props.roomFormData.roomManager}
                    onChange={props.onEditRoom}
                  >
                    <option value="">Select Room Manager</option>
                    {props.staffList.length > 0 &&
                      props.staffList.map((item, index) => {
                        return (
                          <option key={index} value={item.entryID}>
                            {`${item.firstName} ${item.middleName} ${item.surname}`}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Description</label>
                  <textarea
                    cols={3}
                    rows={3}
                    name="description"
                    className="form-control"
                    id="description"
                    value={props.roomFormData.Description}
                    onChange={props.onEditRoom}
                    placeholder="Description"
                  ></textarea>
                </div>
                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Address</label>
                  <textarea
                    cols={3}
                    rows={3}
                    name="address"
                    className="form-control"
                    id="address"
                    value={props.roomFormData.address}
                    onChange={props.onEditRoom}
                    placeholder="Address"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {props.IsFormLoading ? (
                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    />{" "}
                    Please wait...
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={props.onSubmitRoom}
                  className="btn btn-danger waves-effect waves-light"
                >
                  {props.btn ?? "submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import SpecimenBottleForm from "./specimen-bottle-form";


function SpecimenBottle(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [specimentList, setSpecimentList] = useState([])
    const header = ["S/N", "Bottle Name", "submitted By", "Edit"];
    const initialValue = {
        bottleID: "",
        bottleName: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(initialValue)

    const resetForm = () => {
        setFormData(initialValue)
    }


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}laboratory/specimen/bottle/list/all`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setSpecimentList(result.data.data);
                }else {
                    setSpecimentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const  showTable = () => {
        try {
            return specimentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.bottleName}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       bottleID: item.bottleID,
                                       bottleName: item.bottleName,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.bottleName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter specimen bottle name", "error");
            return false;
        }

        if (formData.bottleID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            toast.info("please wait...");
            await axios
                .post(`${serverLink}laboratory/specimen/bottle/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Specimen Bottle Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetForm()
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}laboratory/specimen/bottle/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Specimen Bottle Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetForm()
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Specimen Bottle"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage Specimen Bottle</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    resetForm()
                                                }}>
                                                    <i className="fa fa-plus"></i> Add Specimen Bottle
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable tableID="specimen-bottle" header={header} body={showTable()} title="Specimen Bottle Report"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SpecimenBottleForm
                    title="Manage Specimen Bottle"
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(SpecimenBottle);


import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
import {Rating} from "react-simple-star-rating";
import {FaFrown, FaRegLaughBeam, FaRegMeh, FaSadCry, FaSmile} from "react-icons/fa";

function PainAssessment(props) {
    const { patientSerial, patientName, serviceID, serviceName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)

    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        painRating: '',
        painRatingName: "",
        painComment: "",
        nurseID: `${props.loginData[0]?.userID}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const getItemID = (service_name, service_id) => {
        if (service_name === 'Consultation'){
            return {appointmentID: service_id, admissionID: null}
        }else if (service_name === 'Admission'){
            return {admissionID: service_id, appointmentID: null}
        }else{
            return {}
        }
    }

    const addPainAssessmentRating = async () => {
        if (formData.painRating.toString().trim() === ''){
            toast.error('Please select at least one emotion')
            return false
        }

        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...formData,
            ...getItemID(serviceName, serviceID)
        }

        await axios.post(`${serverLink}patient/appointment/pain-assessment/add`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Pain Assessment Added Successfully");
                setIsFormLoading(false);
                props.setRebuildPainAssessment(generate_token(5));
                document.getElementById("closePain").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding pain-assessment. Please try again!");
            }
        }).catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

   const handlePainAssessmentRating = (e) => {
        let pain_rating = '';
        if (e === 1)
            pain_rating = 'No Pain';
        else if (e === 2)
            pain_rating = 'Mild Pain';
        else if (e === 3)
            pain_rating = 'Moderate Pain';
        else if (e === 4)
            pain_rating = 'Severe Pain';
        else
            pain_rating = 'Very Severe Pain';

        setFormData({
            ...formData,
            painRating: e,
            painRatingName: pain_rating
        })
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>

                            <div className="mb-3 form-group col-md-12">
                                <Rating
                                    onClick={handlePainAssessmentRating}
                                    ratingValue={formData.painRating}
                                    fillColorArray={['#02b324', '#8cbf96','#f5e342', '#e04a4a','#c40202']}
                                    customIcons={[
                                        {icon: <FaRegLaughBeam size={150} /> },
                                        {icon: <FaSmile size={150} /> },
                                        {icon: <FaRegMeh size={160} /> },
                                        {icon: <FaFrown size={160} /> },
                                        {icon: <FaSadCry size={150} /> }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 form-group col-md-12">
                        <label className="form-label">Comment (Optional)</label>
                        <textarea
                            rows={3}
                            cols={3}
                            name="painComment"
                            className="form-control"
                            id="painComment"
                            value={formData.painComment}
                            onChange={onEdit}
                        ></textarea>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addPainAssessmentRating}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PainAssessment);
import React, {useEffect, useState} from "react";
import {currencyConverter, formatDateAndTime, generate_token, getCurrentTime, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function CheckInPatient(props) {
    const { patientSerial, patientName, walletAmount, handleRemount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [appointmentList, setAppointmentList] = useState([])


    const Initial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        appointmentStatus: "Checked In",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(Initial)

    const resetFormData = () => {
        setFormData(Initial)
    }

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        await axios.get(`${serverLink}patient/appointment/scheduled/${patientSerial}`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setAppointmentList(result.data.filter(e=>{
                        let currentDate = new Date();
                        let otherDate = new Date(e.appointmentDate);
                        return  currentDate.toDateString() === otherDate.toDateString()
                    }));
                }else {
                    setAppointmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const handleCheckIn = async (appointment) => {
        setIsFormLoading(true)
        const sendData = {
            ...formData,
            appointmentID: appointment.appointmentID,
            checkInTime: getCurrentTime(),
        }
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/appointment/check-in-patient`, sendData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Patient Checked In Successfully");
                setIsFormLoading(false);
                props.setRebuildAppointment(generate_token(5));
                document.getElementById("closeCheckIn").click();
                getData();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong adding appointment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Department</th>
                                        <th>Doctor</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    appointmentList.length > 0 ?
                                        appointmentList.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.departmentName}</td>
                                                    <td>{item.doctorName}</td>
                                                    <td>{formatDateAndTime(item.appointmentDate, 'date')}</td>
                                                    <td>{item.appointmentTime}</td>
                                                    <td>
                                                        {
                                                            IsFormLoading ?
                                                                <button type="button"
                                                                        className="btn btn-primary ms-auto">
                                                                    <span><span
                                                                        className="spinner-border spinner-border-sm me-2"
                                                                        role="status"/> Please wait...</span>
                                                                </button>
                                                                :
                                                                <a href="#" className={"btn btn-primary btn-sm "}
                                                                   onClick={() => {
                                                                       handleCheckIn(item)
                                                                   }}>
                                                                    Check In
                                                                </a>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                    }) :
                                        <tr>
                                            <td colSpan={5} className="alert alert-danger text-center p-3"><h3>No Scheduled Appointment!</h3></td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(CheckInPatient);
import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {formatDateAndTime, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import {useLocation} from "react-router";
import NewsForm from "./news-form";
import Loader from "../../common/loader";


function News(props) {
    let location = useLocation();
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [newList, setNewsList] = useState([])
    const header = ["S/N", "Title", "Authors", "Image", "Date", "Viewers", "Status", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        EntryID: "",
        Title: "",
        ImagePath: "",
        ImageData: "",
        Description: "",
        ViewCount: "",
        Status: "",
        InsertedBy: `${props.loginData[0]?.userID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, [location]);


    const getData = async () => {
        await axios.get(`${serverLink}news/list/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setNewsList(result.data);
                }else{
                    setNewsList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}news/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return newList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.title}</td>
                        <td>{item.submittedBy}</td>
                        <td> <img src={`${serverLink}public/uploads/news_uploads/${item.image}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td>{formatDateAndTime(item.submittedOn, 'date')}</td>
                        <td>{item.viewCount}</td>
                        <td>{item.status.toString() ==="1" ? "Published" : "Draft"}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.newsID,
                                       Title: item.title,
                                       ImagePath: item.image,
                                       Description: item.description,
                                       ViewCount: item.viewCount,
                                       InsertedBy: item.submittedBy,
                                       Status: item.status,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={()=>showConfirm("Warning", `Are you sure you want to delete this news?`, "warning")
                                   .then( async (confirm) => {
                                       if (confirm) {
                                           delete_item(item.newsID)
                                       }
                                   })}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };


    const onSubmit = async () => {
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter news title", "error");
            return false;
        }
        if (formData.Description.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter news description", "error");
            return false;
        }
        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select news status", "error");
            return false;
        }


        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}news/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', result.data.entry_id)
                            axios.patch(`${serverLink}news/uploadNewsPhoto`, formData2)
                                .then(result => {
                                    toast.success("News Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        ImageData: "",
                                        Description: "",
                                        ViewCount: "",
                                        Status: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("News Added Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("close").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                Title: "",
                                ImagePath: "",
                                ImageData: "",
                                Description: "",
                                ViewCount: "",
                                Status: "",
                            });
                        }
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("NEWS EXIST", "News with the same title already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}news/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImageData !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', formData.EntryID)
                            axios.patch(`${serverLink}news/uploadNewsPhoto`, formData2)
                                .then(result => {
                                    toast.success("News Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("close").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        ImageData: "",
                                        Description: "",
                                        ViewCount: "",
                                        Status: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("News Updated Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("close").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                Title: "",
                                ImagePath: "",
                                ImageData: "",
                                Description: "",
                                ViewCount: "",
                                Status: "",
                            });
                        }
                    } else if (result.data.message === "exist") {
                        showAlert("NEWS EXIST", "News with the same title already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return(IsLoading ? <Loader/> :
        <>
            <PageTitle title={["Home", "News"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Manage News</h4>
                                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                    <div className="ml-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    EntryID: "",
                                                    Title: "",
                                                    ImagePath: "",
                                                    ImageData: "",
                                                    Description: "",
                                                    ViewCount: "",
                                                    Status: "",
                                                });
                                            }}>
                                                <i className="fa fa-plus"></i> Add News
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable tableID="News" header={header} body={showTable()} title="Website News Report"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewsForm
                title="Manage News"
                formData={formData}
                onEdit={onEdit}
                onSubmit={onSubmit}
                IsFormLoading={IsFormLoading}
                onImageChange={onImageChange}
                descriptionHandler={descriptionHandler}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(News);


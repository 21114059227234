import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {currencyConverter, formatDateAndTime, serverLink} from "../../url";
import { connect } from "react-redux";
import Loader from "../../common/loader";
import { setGeneralDetails } from "../../../action/actions";
import { useReactToPrint } from "react-to-print";

function ReceiptsVoucherReport(props) {
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [formData, setFormData] = useState({ month_id: "" })
    const componentRef = useRef();
    const onPrintPage = () => {
        setTimeout(() => {
            handlePrint();
        }, 1000);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getData(val)
    }

    useEffect(() => {
        setTimeout(()=>{
            setIsLoading(false)
        }, 2000)
    }, []);

    const getData = async (month) => {
        if (month !== ""){
            setShowReport(false)
            setIsLoading(true)
            await axios
                .get(`${serverLink}report/payment/monthly-payment?payment_date=${month}`, token)
                .then((result) => {
                    if (result.data.length > 0) {
                        setReportList(result.data);
                    } else {
                        setReportList([]);
                    }
                    setIsLoading(false);
                    setShowReport(true);
                })
                .catch((err) => {
                    console.log("NETWORK ERROR");
                });
        }

    };

    const  getTotal = () => {
        let total_amount = 0;
        reportList.map((item, index) => {
            total_amount += item.amountPaid;
        });
        return total_amount;
    }

    const showTable = () => {
        let report = [];
        try {
            [...new Set(reportList.map(obj => obj.paymentFor))].map((element, int)=>{
                let subTotal = 0;
                report.push(<tr><td colSpan={5}>{<h1 className="text-center text-uppercase text-success">{element} - {formatDateAndTime(formData.month_id, 'month_and_year')}</h1>}</td></tr>)
                reportList.filter(e=>e.paymentFor === element).map((item, index) => {
                    subTotal +=item.amountPaid;
                    report.push(
                        <tr key={index}>
                            <td>{item.paymentReference}</td>
                            <td>{item.itemName}</td>
                            <td>{item.paymentMethod}</td>
                            <td>{item.paymentType}</td>
                            <td>{currencyConverter(item.amountPaid)}</td>
                        </tr>
                    )
                })
                report.push(<tr><td></td><td></td><td></td><td>{<h4 className="text-uppercase text-success">SUB TOTAL</h4>}</td><td>{<h4 className="text-uppercase text-success">{currencyConverter(subTotal)}</h4>}</td></tr>)
            })
            report.push(<tr><td></td><td></td><td></td><td>{<h2 className="text-uppercase text-success"><b>TOTAL</b></h2>}</td><td>{<h2 className="text-uppercase text-success"><b>{currencyConverter(getTotal() ?? 0)}</b></h2>}</td></tr>)

            return report;
        } catch (e) {
            alert(e.message);
        }
    };

    return IsLoading ? (
        <Loader />
    ) : (
        <>
            <PageTitle title={["Home", "Monthly Payment Report"]} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div className="form-group">
                                        <label htmlFor="month_id">Select Year and Month </label>
                                        <input type="month" id="month_id"
                                               className="form-control"
                                               value={formData.month_id}
                                               onChange={onChange}
                                               max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                               required />
                                    </div>
                                    {
                                        showReport ?
                                            <div className="card p-0 m-0">
                                                <div className="card-body p-0">
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-primary col-md-2 float-right"
                                                        onClick={onPrintPage}
                                                    >
                                                        Print
                                                    </button>
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                    <div className="col-md-12">
                                        <div className="card" ref={componentRef}>
                                            <div className="card-body">
                                                {
                                                    showReport ?
                                                        <table className="table table-bordered">
                                                            <thead>
                                                            <tr>
                                                                <th>Payment Ref.</th>
                                                                <th>Payment Item</th>
                                                                <th>Payment Method</th>
                                                                <th>Payment Type</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {showTable()}
                                                            </tbody>
                                                        </table>
                                                        : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnGeneralDetails: (p) => {
            dispatch(setGeneralDetails(p));
        }
    };
};


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        dashboardData: state.DashboardData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptsVoucherReport);

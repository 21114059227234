import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../common/header/header";
import SideBar from "../common/sidebar/sidebar";
import DashBoard from "../dashboard/dashboard";
import Group from "../settings/group/group";
import MenuSettings from "../settings/menu/menu-settings";
import PermissionSettings from "../settings/permission/permission";
import Footer from "../common/footer/footer";
import News from "../website/news/news";
import WebsiteSlider from "../website/slider/slider";
import Department from "../settings/department/department";
import Specialisation from "../settings/specialisation/specialisation";
import SystemUser from "../settings/system-user/system-user";
import ManagePatient from "../patient/manage-patient/manage-patient";
import Gallery from "../website/gallery/gallery";
import GalleryCategory from "../website/gallery/gallery-category";
import Drugs from "../pharmacy/drug/drugs";
import Prescription from "../pharmacy/prescription/prescription";
import PatientDashboard from "../patient/patient-dashboard/patient-dashboard";
import DoctorSchedule from "../doctor/schedule";
import ServiceSettings from "../settings/services/services";
import Building from "../settings/building/building";
import OutpatientList from "../doctor/outpatient/outpatient-list";
import OutpatientDashboard from "../doctor/outpatient/outpatient-dashboard";
import ImagingSettings from "../imaging/imaging-settings/imaging-settings";
import ImagingRequestList from "../imaging/imaging-request/imaging-request";
import ImagingRequestHistory from "../imaging/imaging-request/imaging-request-history";
import ImagingDashboard from "../imaging/imaging-request/imaging-request-dashboard";
import PrescriptionHistory from "../pharmacy/prescription/prescription-history";
import PrescriptionDashboard from "../pharmacy/prescription/prescription-dashboard";
import Laboratories from "../laboratory/lab/lab";
import Specimen from "../laboratory/specimen/specimen";
import AdmissionPage from "../doctor/components/admission-page";
import AdmitPatient from "../doctor/components/admit-patient";
import InpatientList from "../doctor/inpatient/inpatient-list";
import InpatientDashboard from "../doctor/inpatient/inpatient-dashboard";
import SpecimenBottle from "../laboratory/specimen-bottle/specimen-bottle";
import SpecimenBottleVolume from "../laboratory/specimen-bottle-volume/specimen-bottle-volume";
import LabTest from "../laboratory/lab-test/lab-test";
import LabTestPage from "../laboratory/lab-test/lab-test-page";
import PaymentReportDashboard from "../report/payment/dashboard";
import PatientList from "../patient/wallet-topup/wallet-topup";
import AdmitPatients from "../admission/admit-patient/admit-patient";
import AdmissionInpatientList from "../admission/inpatient/inpatient";
import AccidentAndEmergency from "../doctor/accident-and-emergency/accident-and-emergency";
import PatientCheckIn from "../nurse/check-in/check-in";
import PatientCaptureVitals from "../nurse/capture-vitals/capture-vitals";
import ConsultationList from "../doctor/consultation/consultation";
import ConsultationHistory from "../doctor/consultation/consultation-history";
import MonthlyPaymentReport from "../report/payment/monthly-income";
import DailyPaymentReport from "../report/payment/daily-income";
import ReceiptsVoucherReport from "../report/payment/receipts-voucher";

const PageRoutes = (props) => {
  const [toggle, setToggle] = useState("");

  const toggleSidebar = () => {
    const tg = toggle === "" ? "toggle-sidebar" : "";
    setToggle(tg);
  };

  return (
    <div className={toggle}>
      <Header toggleSidebar={toggleSidebar} />
      <SideBar />
      <div className="page-wrapper">
        <Routes>
          <Route path={"/"} element={<DashBoard />} />
          <Route path={"/dashboard"} element={<DashBoard />} />

          {/*/!* SETTINGS *!/*/}
          <Route path="/settings/permission/group" element={<Group />} />
          <Route
            path="/settings/permission/menu-settings"
            element={<MenuSettings />}
          />
          <Route
            path="/settings/permission/permission-settings"
            element={<PermissionSettings />}
          />
          <Route path="/settings/department" element={<Department />} />
          <Route path="/settings/specialisation" element={<Specialisation />} />
          <Route path="/settings/system-user" element={<SystemUser />} />
          <Route path="/settings/services" element={<ServiceSettings />} />
          <Route path="/settings/hospital-building" element={<Building />} />

          {/*/!* PATIENT *!/*/}
          <Route path="/patient/manage-patient" element={<ManagePatient />} />
          <Route path="/patient/dashboard/:slug" element={<PatientDashboard />} />
          <Route path="/patient/wallet-topup" element={<PatientList />} />

          {/*/!* DOCTOR *!/*/}
          <Route path="/doctor/schedule" element={<DoctorSchedule />} />
          <Route path="/doctor/dashboard" element={<PatientDashboard />} />
          <Route path="/doctor/consultation" element={<ConsultationList access={'Doctor'} />} />
          <Route path="/doctor/consultation-history" element={<ConsultationHistory access={'Doctor'} />} />
          <Route path="/doctor/outpatient" element={<OutpatientList access={'Doctor'} />} />
          <Route path="/doctor/accident-and-emergency" element={<AccidentAndEmergency access={'Doctor'} />} />
          <Route path="/doctor/inpatient" element={<InpatientList access={'Doctor'}/>} />
          <Route path="/doctor/outpatient/dashboard/:slug" element={<OutpatientDashboard access={'Doctor'} />}/>
          <Route path="/doctor/inpatient/dashboard/:slug" element={<InpatientDashboard access={'Doctor'} />}/>
          <Route path="/admission/patient/:slug" element={<AdmissionPage />}/>
          <Route path="/admit-patient/:slug/:slug" element={<AdmitPatient />}/>

          {/*/!* NURSE *!/*/}
          <Route path="/nurse/outpatient" element={<OutpatientList access={'Nurse'} />} />
          <Route path="/nurse/accident-and-emergency" element={<AccidentAndEmergency access={'Nurse'} />} />
          <Route path="/nurse/inpatient" element={<InpatientList access={'Nurse'}/>} />
          <Route path="/nurse/outpatient/dashboard/:slug" element={<OutpatientDashboard access={'Nurse'} />}/>
          <Route path="/nurse/inpatient/dashboard/:slug" element={<InpatientDashboard access={'Nurse'} />}/>
          <Route path="/nurse/check-in" element={<PatientCheckIn />}/>
          <Route path="/nurse/capture-vitals" element={<PatientCaptureVitals />}/>

          {/*/!* SERVICE DESK *!/*/}
          <Route path="/service-desk/outpatient" element={<OutpatientList access={'Service-Desk'} />} />
          <Route path="/service-desk/accident-and-emergency" element={<AccidentAndEmergency access={'Service-Desk'} />} />
          <Route path="/service-desk/inpatient" element={<InpatientList access={'Service-Desk'}/>} />
          <Route path="/service-desk/outpatient/dashboard/:slug" element={<OutpatientDashboard access={'Service-Desk'} />}/>
          <Route path="/service-desk/inpatient/dashboard/:slug" element={<InpatientDashboard access={'Service-Desk'} />}/>

          {/*/!* PHARMACY *!/*/}
          <Route path="/pharmacy/drugs" element={<Drugs />} />
          <Route path="/pharmacy/prescription" element={<Prescription />} />
          <Route path="/pharmacy/prescription/history" element={<PrescriptionHistory />} />
          <Route path="/pharmacy/prescription/dashboard" element={<PrescriptionDashboard />} />

          {/*/!* LABORATORY *!/*/}
          <Route path="/lab/list" element={<Laboratories />} />
          <Route path="/lab/specimen" element={<Specimen />} />
          <Route path="/lab/specimen/bottle" element={<SpecimenBottle />} />
          <Route path="/lab/specimen/bottle/volume" element={<SpecimenBottleVolume />} />
          <Route path="/lab/test" element={<LabTest />} />
          <Route path="/lab/test-page" element={<LabTestPage />} />

          {/*/!* WEBSITE *!/*/}
          <Route path="/website/slider" element={<WebsiteSlider />} />
          <Route path="/website/gallery" element={<Gallery />} />
          <Route path="/website/gallery/category" element={<GalleryCategory />}/>
          <Route path="/website/news" element={<News />} />
          {/*<Route path="/website/gallery" element={<div className="text-center alert alert-info"><h1>Page Under Construction</h1></div>} />*/}

          {/*/!* IMAGING *!/*/}
          <Route path="/imaging/settings" element={<ImagingSettings />} />
          <Route path="/imaging/request" element={<ImagingRequestList />} />
          <Route path="/imaging/history" element={<ImagingRequestHistory />} />
          <Route path="/imaging/dashboard" element={<ImagingDashboard />} />

          {/*/!* ADMISSION *!/*/}
          <Route path="/admission/admit-patient" element={<AdmitPatients />} />
          <Route path="/admission/inpatient" element={<AdmissionInpatientList />} />

          {/*/!* REPORT *!/*/}
          <Route path="/report/payment" element={<PaymentReportDashboard />} />
          <Route path="/report/monthly-income" element={<MonthlyPaymentReport />} />
          <Route path="/report/daily-income" element={<DailyPaymentReport />} />
          <Route path="/report/receipts-voucher" element={<ReceiptsVoucherReport />} />

          <Route path="*" element={<DashBoard />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};
export default PageRoutes;

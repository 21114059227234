import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {currencyConverter, generate_token, serverLink} from "../../url";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {Link, useParams} from "react-router-dom";
import {FaBedPulse} from "react-icons/fa6";
import {BsBan} from "react-icons/bs";
import {Cancel, CheckroomRounded} from "@mui/icons-material";
import {GiOccupy} from "react-icons/gi";
import MakePayment from "../../finance/choose-payment/choose-payment";

function AdmitPatient(props) {
    const token = props.loginData[0]?.token;
    const {slug} = useParams();
    let patient_id = '';
    let url = window.location.href;
    let split = url.split('/');
    if (split.length > 0) {
        let lastIndex = split[split.length -2]
        patient_id = lastIndex
    }

    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showBeds, setShowBeds] = useState(false)
    const [showInvoice, setShowInvoice] = useState(false)
    const [roomList, setRoomList] = useState([])
    const [bedList, setBedList] = useState([])
    const [patientDetails, setPatientDetails] = useState({})
    const [block, setBlock] = useState("")

    const initialValue = {
        bedID: "",
        roomID: "",
        blockID: "",
        roomName: "",
        blockName: "",
        typeName: "",
        patientSerial: "",
        roomAmount: "",
        incurredAmount: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(initialValue)

    const resetForm = () => {
        setFormData(initialValue)
    }


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}admission/hospital/room/${atob(slug)}/${atob(patient_id)}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setRoomList(result.data.data);
                    setPatientDetails(result.data.patient[0]);
                    if (result.data.data.length > 0){
                        setBlock(result.data.data[0].blockName)
                    }
                }else {
                    setRoomList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getRoomBeds = async (room_id) => {
        setIsLoading(true)
        await axios.get(`${serverLink}admission/hospital/bed/${room_id}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setBedList(result.data.data);
                }else {
                    setBedList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}laboratory/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const initiatePayment = async (paymentData) => {
        setIsFormLoading(true)
        toast.info("please wait...");
        const sendData = {
            ...paymentData,
            ...formData
        }

        await axios.post(`${serverLink}payment/post-payment`, sendData, token).then(async (res) => {
            if (res.data.message === "success") {
                await axios.post(`${serverLink}admission/admit-patient`, sendData, token).then((res) => {
                    if (res.data.message === "success") {
                        toast.success("Payment Successfully");
                        setIsFormLoading(false);
                        document.getElementById("closePayment").click();
                    } else {
                        setIsFormLoading(false);
                        toast.error("Something went wrong updating payment. Please try again!");
                    }
                })
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong posting payment. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }


    const showAllRooms = () => {
        resetForm()
        setShowBeds(false)
        setShowInvoice(false)
    }

    const showAllBeds = () => {
        setShowBeds(true)
        setShowInvoice(false)
    }

    const onChooseRoom = async (room) => {
        setFormData({
            ...formData,
            roomID: room.roomID,
            roomName: room.roomName,
            blockID: room.blockID,
            blockName: room.blockName,
            typeName: room.typeName,
            patientSerial: patientDetails.patientSerial,
            roomAmount: parseFloat(room.roomAmount),
            incurredAmount: parseFloat(room.roomAmount),
        })
        await getRoomBeds(room.roomID)
        setShowBeds(true)
    }


    const onChooseBed = async (bed) => {
        setFormData({...formData, bedID: bed.bedID, bedName: bed.bedName})
        setShowBeds(false)
        setShowInvoice(true)
    }

    const statusColor = (status) => {
        switch (status) {
            case 'Available':
                return 'round-info';
            case 'Occupied':
                return 'round-danger';
            case 'Under Maintenance':
                return 'round-warning';
            default:
                return 'round-secondary';
        }
    };

    const clearPayment = () => {
        document.getElementById('pay-now').click();
    }



    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "Patient Admission"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                            <br/>
                                            <p className="text-muted">{patientDetails?.patientName}</p>
                                        </div>
                                        <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                            <br/>
                                            <p className="text-muted">{patientDetails?.patientSerial}</p>
                                        </div>
                                        <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                            <br/>
                                            <p className="text-muted">{currencyConverter(patientDetails?.walletAmount)}</p>
                                        </div>
                                        <div className="col-md-12"
                                             style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                                    </div>
                                    {
                                        showBeds &&  <div className="text-right">
                                            <button onClick={showAllRooms} className="btn btn-default btn-outline" type="button"> <span><span className="fa fa-arrow-alt-circle-left fa-1x"></span> View all rooms</span> </button>
                                        </div>
                                    }

                                    {
                                        showInvoice &&  <div className="text-right">
                                            <button onClick={showAllBeds} className="btn btn-default btn-outline" type="button"> <span><span className="fa fa-arrow-alt-circle-left fa-1x"></span> View all Beds</span> </button>
                                        </div>
                                    }

                                    {
                                        showBeds ?
                                            <div className="col-md-12">
                                                <div className="card card-body">
                                                    <h3><b><span><FaBedPulse/></span> {formData.roomName} Beds</b> </h3>
                                                    <hr/>
                                                    <div className="row pt-3" style={{backgroundColor: '#eeeeee'}}>
                                                        {
                                                            bedList.length > 0 ?
                                                                bedList.map((item, index)=>{
                                                                    return (
                                                                        <div key={index} className="col-lg-3 col-md-6">
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    <div className="d-flex flex-row">
                                                                                        <div className={`round align-self-center ${statusColor(item.status)}`}><FaBedPulse size={28}/></div>
                                                                                        <div className="m-l-10 align-self-center">
                                                                                            <h3 className="text-uppercase">{item.bedName}</h3>
                                                                                            <div className="d-flex justify-content-between col-md-12  text-left">
                                                                                                <div className="text-muted col-md-6">{item.status}</div>
                                                                                                {
                                                                                                    item.status !== 'Available' ?
                                                                                                        <button disabled className="btn btn-danger btn-outline " type="button"> <span> <FaBedPulse/> Bed is Occupied</span> </button> :
                                                                                                        <button onClick={()=> onChooseBed(item)} className="btn btn-default btn-outline " type="button"> <span> <FaBedPulse/> Admin Patient</span> </button>
                                                                                                }

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : <div className="alert alert-danger col-md-12 text-center"><h3>No Record Found</h3></div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            :   showInvoice ? <div className="row">

                                            <div className="col-md-12">
                                                <h3 className="box-title m-t-0"><b>Room/Bed Details</b></h3>
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                        <tr>
                                                            <td width="390">Room</td>
                                                            <td> {formData.roomName} </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Room Type</td>
                                                            <td>{formData.typeName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Block</td>
                                                            <td>{formData.blockName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bed Number</td>
                                                            <td> {formData.bedName} </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Amount Due</td>
                                                            <td> {currencyConverter(formData.roomAmount)} </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12" style={{marginTop: '40px'}}>
                                                <div className="pull-right m-t-30 text-right">
                                                    <hr/>
                                                    <h3><b>Total Amount Due :</b> {currencyConverter(formData.roomAmount)}</h3>
                                                </div>
                                                <div className="clearfix"></div>
                                                <hr/>
                                                <div className="text-right">
                                                    <button id="print" onClick={clearPayment} className="btn btn-default btn-outline" type="button"> <span> Proceed to payment</span> </button>
                                                </div>
                                            </div>
                                        </div> :
                                            <div className="">
                                                <h2><strong> <i className="mdi mdi-home-modern mdi-36px"></i> {block} Rooms</strong></h2>
                                                <hr/>
                                                <div className="row">
                                                    {
                                                        roomList.length > 0 ? roomList.map((item, index)=> {
                                                            const availability = parseInt(item.bedCapacity) - parseInt(item.bedOccupied);
                                                            return (
                                                                <div key={index} className="col-md-3 border border-4 mr-5 ml-4 mb-3">
                                                                    <center><img src={require('../../../images/room3.jpeg')} width="80%"
                                                                                 height="150" className="" alt="Block"/></center>
                                                                    <hr/>
                                                                    <div className="col-md-12 text-secondary">
                                                                        <h5><strong>{item.roomName}</strong></h5>
                                                                        <small><b>Room Type: </b> {item.typeName}</small><br/>
                                                                        <small><b>No. of Beds: </b> {item.bedCapacity}</small><br/>
                                                                        <small><b>Available Beds: </b> {availability}</small><br/><br/>
                                                                        {
                                                                            availability === 0 ?
                                                                                <button disabled type="button" className="btn btn-danger btn-sm form-control mb-3">Room is occupied </button> :
                                                                                <button type="button" onClick={()=>onChooseRoom(item)} className="btn btn-primary btn-sm form-control mb-3"> View Beds
                                                                                </button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : <div className="alert alert-danger col-md-12 text-center"><h3>No Record Found</h3></div>
                                                    }
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button data-toggle="modal" style={{display: 'none'}} id="pay-now" data-target={`#payment-modal`}>Open</button>
                <MakePayment
                    id="payment-modal"
                    title="Make Payment"
                    close="closePayment"
                    patientSerial={patientDetails.patientSerial}
                    patientName={patientDetails.patientName}
                    paymentFor="Admission"
                    itemName={`${formData.blockName} => ${formData.typeName} =>  ${formData.roomName} =>  ${formData.bedName}`}
                    itemAmount={formData.roomAmount}
                    amountDue={formData.roomAmount}
                    paymentType="Debit"
                    IsFormLoading={IsFormLoading}
                    onPostPayment={initiatePayment}
                    onError={()=>{console.log("Error")}}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(AdmitPatient);


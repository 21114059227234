import PageTitle from "../../common/pagetitle/pagetitle";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {decryptData, encryptData, formatDate, serverLink} from "../../url";
import {showAlert, showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import SystemUserForm from "./system-user-form";

function SystemUser(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [userList, setUserList] = useState([])
    const [userSpecialisationList, setUserSpecialisationList] = useState([])
    const [specialisationList, setSpecialisationList] = useState([])
    const header = ["S/N", "UserID",  "Title", "User Name", "Gender", "Phone Number", "Email Address", "Role", "Specialisation", "submittedBy", "Edit"];
    const initialData = {
        entryID: "",
        userID: "",
        title: "",
        firstName: "",
        middleName: "",
        surname: "",
        gender: "",
        passport: "",
        phoneNumber: "",
        emailAddress: "",
        dateOfFirstAppointment: "",
        dateOfRenewedContract: "",
        employmentStatus: "",
        role: "",
        specialisation: [],
        specialisationData: [],
        password: "123456789",
        facebook: null,
        linkedin: null,
        twitter: null,
        googleScholar: null,
        academia: null,
        researchGate: null,
        aboutDoctor: null,
        status: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(initialData)
     const resetFormData = () => {
       setFormData(initialData)
     }


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/settings/user/list/all`)
            .then((result) => {
                let specialisation = [];
                if (result.data.message === 'success') {
                    setUserList(result.data.users);
                    setUserSpecialisationList(result.data.usersSpecialisation);
                    result.data.specialisations.map((sp) => {
                        specialisation.push({value: sp.specialisationID, label: sp.specialisationName})
                    })
                    setSpecialisationList(specialisation);
                }else {
                    setUserList([])
                    setUserSpecialisationList([])
                    setSpecialisationList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }


    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}staff/settings/user/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return userList.map((item, index) => {
                let specialisation_ = [];
                if (userSpecialisationList.length > 0){
                    userSpecialisationList.filter(e=>e.doctorID === item.userID).map((sp) => {
                        specialisation_.push(sp.specialisationName)
                    })
                }
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.userID}</td>
                        <td>{item.title}</td>
                        <td>{item.firstName} {item.middleName} {item.surname}</td>
                        <td>{item.gender}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.emailAddress}</td>
                        <td>{item.role}</td>
                        <td>{specialisation_.map((e)=> <div className="badge badge-sm badge-pill badge-info">{e}</div>)}</td>
                        <td>{item.submittedBy}</td>
                        <td>
                            <a href="#" className={"btn btn-primary btn-sm "} data-toggle="modal" data-target="#responsive-modal"
                               onClick={() => {
                                   let user_specialisation = [];
                                   let user_specialisation_id = [];
                                   if (userSpecialisationList.length > 0){
                                       userSpecialisationList.filter(e=>e.doctorID === item.userID).map((sp) => {
                                           user_specialisation.push({value: sp.specialisationID, label: sp.specialisationName})
                                           user_specialisation_id.push(sp.specialisationID)
                                       })
                                   }
                                   setFormData({
                                       ...formData,
                                       entryID: item.entryID,
                                       userID: item.userID,
                                       title: item.title,
                                       firstName: item.firstName,
                                       middleName: item.middleName,
                                       surname: item.surname,
                                       gender: item.gender,
                                       emailAddress: item.emailAddress,
                                       phoneNumber: item.phoneNumber,
                                       role: item.role,
                                       specialisation: user_specialisation_id,
                                       specialisationData: user_specialisation,
                                       dateOfFirstAppointment: formatDate(item.dateOfFirstAppointment),
                                       dateOfRenewedContract: formatDate(item.dateOfRenewedContract),
                                       employmentStatus: item.employmentStatus,
                                       status: item.status,
                                       passport: item.passport,
                                       password: decryptData(item.password),
                                       twitter: item.twitter,
                                       googleScholar: item.googleScholar,
                                       academia: item.academia,
                                       researchGate: item.researchGate,
                                       aboutDoctor: item.aboutDoctor,
                                   });
                               }}>
                                Edit
                            </a></td>
                        {/*<td>*/}
                        {/*    <a href="#" className={"btn btn-danger btn-sm "}*/}
                        {/*       onClick={()=>showConfirm("Warning", `Are you sure you want to delete this user?`, "warning")*/}
                        {/*           .then( async (confirm) => {*/}
                        {/*               if (confirm) {*/}
                        {/*                   delete_item(item.userID)*/}
                        {/*               }*/}
                        {/*           })}>*/}
                        {/*        Delete*/}
                        {/*    </a></td>*/}
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.title.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }

        if (formData.firstName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }

        if (formData.surname.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }

        if (formData.gender.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select gender", "error");
            return false;
        }

        if (formData.phoneNumber.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }

        if (formData.dateOfFirstAppointment.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter date of first appointment", "error");
            return false;
        }

        if (formData.employmentStatus.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select employment status", "error");
            return false;
        }

        if (formData.role.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select staff role", "error");
            return false;
        }

        if (formData.status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select staff status", "error");
            return false;
        }

        if (formData.password.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter password", "error");
            return false;
        }


        if (formData.entryID === "") {
            let sendData = {
                ...formData,
                password: encryptData(formData.password),
                dateOfRenewedContract: formData.dateOfFirstAppointment ?? null
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/settings/user/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                password: encryptData(formData.password),
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/settings/user/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("User Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("close").click();
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }
    }

   const onSpecialisationChange = (e) => {
        let data = [];
        e.map((spec) => {
            data.push(spec.value)
        })
       setFormData({
           ...formData,
           specialisation: data,
           specialisationData: e
       })
    }

    return(IsLoading ? <Loader/> :
            <>
                <PageTitle title={["Home", "System Users"]} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Manage System Users</h4>
                                    <div className="d-flex no-block align-items-center m-b-30 mt-3">
                                        <div className="ml-auto">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-info" data-toggle="modal" data-target="#responsive-modal" onClick={() => {
                                                    resetFormData();
                                                }}>
                                                    <i className="fa fa-plus"></i> Add User
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <DataTable tableID="User" header={header} body={showTable()} title="Users Report"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SystemUserForm
                    title="Manage User"
                    formData={formData}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    specialisationList={specialisationList}
                    onSpecialisationChange={onSpecialisationChange}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(SystemUser);


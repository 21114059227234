import React from "react";

export default function RoomTypeForm(props) {
  return (
    <>
      <div
        id={`${
          typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`}
        className="modal fade bs-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myLargeModalLabel">
                {props.title ?? ""}
              </h4>
              <button
                type="button"
                className={`${
                  typeof props.close !== "undefined"
                    ? props.close
                    : "closeModal"
                }`}
                id={`${
                  typeof props.close !== "undefined" ? props.close : "close"
                }`}
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="mb-3 form-group col-md-12">
                  <label className="form-label">Room Type Name</label>
                  <input
                    type="text"
                    name="typeName"
                    className="form-control"
                    id="typeName"
                    value={props.roomTypeFormData.typeName}
                    onChange={props.onEditRoomType}
                    placeholder="Room Type Name"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {props.IsFormLoading ? (
                <button type="button" className="btn btn-primary ms-auto">
                  <span>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    />{" "}
                    Please wait...
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={props.onSubmitRoomType}
                  className="btn btn-danger waves-effect waves-light"
                >
                  {props.btn ?? "submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

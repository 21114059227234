import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import { serverLink } from "../../url";
import axios from "axios";
import { connect } from "react-redux";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import ImagingLabForm from "./imaging-lab-form";
import ImagingLabTypeForm from "./imaging-lab-type-form";

function ImagingSettings(props) {
  const token = props.loginData[0]?.token;
  const [IsLoading, setIsLoading] = useState(true);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [labList, setLabList] = useState([]);
  const [labTypeList, setLabTypeList] = useState([]);

  const labHeader = ["S/N", "Lab Name", "Action"];
  const labTypeHeader = [
    "S/N",
    "Image Name",
    "Lab Name",
    "Price",
    "Description",
    "Action",
  ];

  const labInitial = {
    imagingLabID: "",
    labName: "",
    submittedBy: `${props.loginData[0]?.userID}`,
    updatedBy: `${props.loginData[0]?.userID}`,
  };

  const labTypeInitial = {
    imagingTypeID: "",
    imageName: "",
    labID: "",
    price: "",
    description: "",
    imagePath: "",
    submittedBy: `${props.loginData[0]?.userID}`,
      updatedBy: `${props.loginData[0]?.userID}`,
  };

  const [labFormData, setLabFormData] = useState(labInitial);
  const [labTypeFormData, setLabTypeFormData] = useState(labTypeInitial);

  const resetLabFormData = () => setLabFormData(labInitial);
  const resetLabTypeFormData = () => setLabTypeFormData(labTypeInitial);

  useEffect(() => {
    getData().then((r) => {});
  }, [""]);

  const getData = async () => {
    await axios
        .get(`${serverLink}imaging/settings/data`, token)
        .then((result) => {
          setLabList(result.data.lab);
          setLabTypeList(result.data.type);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          showAlert(
              "NETWORK ERROR",
              "Please check your connection and try again!",
              "error"
          );
        });
  };

  const showLabTable = () => {
    try {
      return (
          labList &&
          labList.map((item, index) => {
            return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.labName}</td>
                  <td>
                    <a
                        href="#"
                        className={"btn btn-primary btn-sm "}
                        data-toggle="modal"
                        data-target="#lab-modal"
                        onClick={() => {
                          setLabFormData({
                            ...labFormData,
                            imagingLabID: item.imagingLabID,
                            labName: item.labName,
                          });
                        }}
                    >
                      Edit
                    </a>
                  </td>
                </tr>
            );
          })
      );
    } catch (e) {
      alert(e.message);
    }
  };
  const showLabTypeTable = () => {
    try {
      return (
          labTypeList &&
          labTypeList.map((item, index) => {
              let lab = labList.filter((e) => e.imagingLabID === item.labID);
            return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.imageName}</td>
                    <td>{lab[0].labName}</td>
                  <td>{item.price}</td>
                  <td>{item.description}</td>
                  <td>
                    <a
                        href="#"
                        className={"btn btn-primary btn-sm "}
                        data-toggle="modal"
                        data-target="#lab-type-modal"
                        onClick={() => {
                          setLabTypeFormData({
                            ...labTypeFormData,
                              imagingTypeID: item.imagingTypeID,
                            imageName: item.imageName,
                            labID: item.labID,
                            price: item.price,
                            description: item.description,
                            imagePath: item.imagePath,
                          });
                        }}
                    >
                      Edit
                    </a>
                  </td>
                </tr>
            );
          })
      );
    } catch (e) {
      alert(e.message);
    }
  };

  const onEditLab = (e) => {
    setLabFormData({
      ...labFormData,
      [e.target.id]: e.target.value,
    });
  };
  const onEditLabType = (e) => {
    setLabTypeFormData({
      ...labTypeFormData,
      [e.target.id]: e.target.value,
    });
  };

  const onSubmitLab = async () => {
    setIsFormLoading(true);
    if (labFormData.imagingLabID === "") {
      let sendData = {
        ...labFormData,
      };
      await axios
          .post(`${serverLink}imaging/settings/lab/add`, sendData, token)
          .then((result) => {
            if (result.data.message === "success") {
              setIsFormLoading(false);
              toast.success("Lab Added Successfully");
              getData();
              document.getElementById("closeModal5").click();
              resetLabTypeFormData();
            }
            if (result.data.message === "exist") {
              setIsFormLoading(false);
              showAlert(
                  "Error",
                  `Lab with given details already exist!`,
                  "error"
              );
            }
            if (result.data.message === "failed") {
              setIsFormLoading(false);
              showAlert("Error", `Process failed, please try again!`, "error");
            }
          })
          .catch((error) => {
            setIsFormLoading(false);
            if (
                error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.errors
            ) {
              error.response.data.errors.forEach((error) => {
                showAlert(
                    `${error.msg}`,
                    `Enter correct input for ${error.path}`,
                    "error"
                );
              });
            }
          });
    }
    if (labFormData.imagingLabID !== "") {
      let sendData = {
        ...labFormData,
      };
      setIsFormLoading(true);
      await axios
          .post(`${serverLink}imaging/settings/lab/update`, sendData, token)
          .then((result) => {
            if (result.data.message === "success") {
              toast.success("Lab Updated Successfully");
              setIsFormLoading(false);
              getData();
              document.getElementById("closeModal5").click();
              resetLabTypeFormData();
            }
            if (result.data.message === "failed") {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
              );
            }
            if (result.data.message === "exist") {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Lab with given details already exist!",
                  "error"
              );
            } else {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
              );
            }
          })
          .catch((error) => {
            setIsFormLoading(false);
            if (
                error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.errors
            ) {
              error.response.data.errors.forEach((error) => {
                showAlert(
                    `${error.msg}`,
                    `Enter correct input for ${error.path}`,
                    "error"
                );
              });
            }
          });
    }
  };

  const onSubmitLabType = async () => {
    setIsFormLoading(true);
    if (labTypeFormData.imagingTypeID === "") {
      let sendData = {
        ...labTypeFormData,
      };
      await axios
          .post(`${serverLink}imaging/settings/lab-type/add`, sendData, token)
          .then((result) => {
            if (result.data.message === "success") {
              setIsFormLoading(false);
              toast.success("Lab Type Added Successfully");
              getData();
              document.getElementById("closeModal5").click();
              resetLabTypeFormData();
            }
            if (result.data.message === "exist") {
              setIsFormLoading(false);
              showAlert(
                  "Error",
                  `Lab Type with given details already exist!`,
                  "error"
              );
            }
            if (result.data.message === "failed") {
              setIsFormLoading(false);
              showAlert("Error", `Process failed, please try again!`, "error");
            }
          })
          .catch((error) => {
            setIsFormLoading(false);
            if (
                error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.errors
            ) {
              error.response.data.errors.forEach((error) => {
                showAlert(
                    `${error.msg}`,
                    `Enter correct input for ${error.path}`,
                    "error"
                );
              });
            }
          });
    }
    if (labTypeFormData.imagingTypeID !== "") {
      let sendData = {
        ...labTypeFormData,
      };
      setIsFormLoading(true);
      await axios
          .post(`${serverLink}imaging/settings/lab-type/update`, sendData, token)
          .then((result) => {
            if (result.data.message === "success") {
              toast.success("Lab Type Updated Successfully");
              setIsFormLoading(false);
              getData();
              document.getElementById("closeModal5").click();
              resetLabTypeFormData();
            }
            if (result.data.message === "failed") {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
              );
            }
            if (result.data.message === "exist") {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Lab Type with given details already exist!",
                  "error"
              );
            } else {
              setIsFormLoading(false);
              showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
              );
            }
          })
          .catch((error) => {
            setIsFormLoading(false);
            if (
                error.response &&
                error.response.status === 400 &&
                error.response.data &&
                error.response.data.errors
            ) {
              error.response.data.errors.forEach((error) => {
                showAlert(
                    `${error.msg}`,
                    `Enter correct input for ${error.path}`,
                    "error"
                );
              });
            }
          });
    }
  };

  console.log("LAB LIST", labList);
  console.log("LAB type LIST", labTypeList);

  return IsLoading ? (
      <Loader />
  ) : (
      <>
        <PageTitle title={["Home", "Imaging"]} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs customtab" role="tablist">
                    <li className="nav-item">
                      {" "}
                      <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#lab"
                          role="tab"
                      >
                      <span className="hidden-sm-up">
                          <i className="fa-regular mdi mdi-hospital"></i>
                      </span>{" "}
                        <span className="hidden-xs-down text-secondary">Lab</span>
                      </a>{" "}
                    </li>
                    <li className="nav-item">
                      {" "}
                      <a
                          className="nav-link "
                          data-toggle="tab"
                          href="#lab-type"
                          role="tab"
                      >
                      <span className="hidden-sm-up">
                        <i className="fa fa-home text-secondary"></i>
                      </span>{" "}
                        <span className="hidden-xs-down text-secondary">
                        Lab Type
                      </span>
                      </a>{" "}
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="lab" role="tabpanel">
                      <div className="d-flex no-block align-items-center m-b-30 mt-3">
                        <h4 className="card-title">Lab</h4>
                        <div className="ml-auto">
                          <div className="btn-group">
                            <button
                                type="button"
                                className="btn btn-info"
                                data-toggle="modal"
                                data-target="#lab-modal"
                                onClick={() => resetLabFormData()}
                            >
                              <i className="fa fa-plus"></i> Add Lab
                            </button>
                          </div>
                        </div>
                      </div>
                      <DataTable
                          tableID="lab-table"
                          header={labHeader}
                          body={showLabTable()}
                          title="Lab List"
                      />
                    </div>
                    <div className="tab-pane  p-10" id="lab-type" role="tabpanel">
                      <div className="d-flex no-block align-items-center m-b-30 mt-3">
                        <h4 className="card-title">Lab Type</h4>
                        <div className="ml-auto">
                          <div className="btn-group">
                            <button
                                type="button"
                                className="btn btn-info"
                                data-toggle="modal"
                                data-target="#lab-type-modal"
                                onClick={() => resetLabTypeFormData()}
                            >
                              <i className="fa fa-plus"></i> Lab Type
                            </button>
                          </div>
                        </div>
                      </div>
                      <DataTable
                          tableID="room-type-table"
                          header={labTypeHeader}
                          body={showLabTypeTable()}
                          title="Lab Type List"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ImagingLabForm
            title="Lab Form"
            labFormData={labFormData}
            onEditLab={onEditLab}
            onSubmitLab={onSubmitLab}
            IsFormLoading={IsFormLoading}
            setLabFormData={labFormData}
            id="lab-modal"
        />

        <ImagingLabTypeForm
            title="Lab Type Form"
            labTypeFormData={labTypeFormData}
            onEditLabType={onEditLabType}
            onSubmitLabType={onSubmitLabType}
            IsFormLoading={IsFormLoading}
            labList={labList}
            setLabTypeFormData={labTypeFormData}
            id="lab-type-modal"
        />
      </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};
export default connect(mapStateToProps, null)(ImagingSettings);
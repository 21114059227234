import {formatDateAndTime, serverLink} from "../../url";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loader from "../../common/loader";
import DataTable from "../../common/data-table/data-table";
import {connect} from "react-redux";
import {showConfirm} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

const PatientClinicalTask = ({patientSerial, serviceID, serviceName, loginData, handleRemount}) => {
    const token = loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(true)
    const [taskList, setTaskList] = useState([])
    const header = ["S/N", "Task", "Added By", "Added Date",  "Status", "Action"];

    useEffect( () => {
        getData();
    }, [handleRemount]);

    const getData = async () => {
        setIsLoading(true)
        await axios.get(`${serverLink}patient/appointment/task/${serviceName.toLowerCase()}/list/${patientSerial}/${serviceID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    setTaskList(result.data.task);
                }else {
                    setTaskList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    const  showTable = () => {
        try {
            return taskList.length > 0 &&  taskList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td>{index +1}</td>
                        <td>{item.task}</td>
                        <td>{item.submittedBy}</td>
                        <td>{formatDateAndTime(item.submittedOn, 'date_and_time')}</td>
                        <td>{item.taskStatus === 'Completed' ? <span className="badge badge-pill badge-success font-weight-bold">{item.taskStatus}</span> : <span className="badge badge-pill badge-danger font-weight-bold">{item.taskStatus}</span>}</td>
                        <td>
                            {item.taskStatus === 'Completed' ? <>--</> :
                                <a href="#" className={"btn btn-info btn-sm "}
                                   onClick={() => {
                                       showConfirm("Warning", `Are you sure you want to mark this task completed?`, "warning")
                                           .then( async (confirm) => {
                                               if (confirm) {
                                                   mark_completed(item.appointmentTaskID)
                                               }
                                           })
                                   }}>
                                Mark Completed
                            </a>}
                            </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const mark_completed = async (id) => {
        toast.info("please wait...");
        const sendData = {
            appointmentTaskID: id,
            submittedBy: loginData[0]?.userID,
        }
        await axios.patch(`${serverLink}patient/appointment/task/update`, sendData, token).then((res) => {
            if (res.data.message === "success") {
                toast.success("Task marked as completed");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    return (IsLoading ? <Loader/> :
            <div className="">
                <div className="table-responsive">
                    <h5 className="card-title">Patient Clinical Task</h5>
                    <DataTable tableID="patient-clinical-task" header={header} body={showTable()} title="Patient Clinical Task Report"/>
                </div>
            </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(PatientClinicalTask);


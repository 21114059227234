import PageTitle from "../../common/pagetitle/pagetitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {decryptData, encryptData, formatDate, formatDateAndTime, serverLink} from "../../url";
import { showAlert, showConfirm } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import DataTable from "../../common/data-table/data-table";
import Loader from "../../common/loader";
import ManagePatientForm from "./manage-patient-form";
import StateData from "../../resources/state_and_lga.json";
import CountryData from "../../resources/country.json";
import {Link} from "react-router-dom";
import {setDashboardDetails, setGeneralDetails, setLoginDetails, setPermissionDetails} from "../../../action/actions";

function ManagePatient(props) {
  const token = props.loginData[0]?.token;
  const [IsLoading, setIsLoading] = useState(true);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);

  const [formData, setFormData] = useState({
    patientID: "",
    title: "",
    firstName: "",
    middleName: "",
    surname: "",
    passport: "",
    ImagePath: "",
    emailAddress: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: "",
    bloodGroup: "",
    maritalStatus: "",
    nextOfKinName: "",
    nextOfKinPhone: "",
    nextOfKinRelationship: "",
    residentialAddress: "",
    nationality: "",
    state: "",
    lga: "",
    patientSerial: "",
    occupation: "",
    altPhoneNumber: "",
    password: "",
    ward: "",
    zipCode: "",
    officeAddress: "",
    religion: "",
    submittedBy: `${props.loginData[0]?.userID}`,
  });

  const header = [
    "S/N",
    "Patient ID",
    "Name",
    "Gender",
    "Phone Number",
    "Email Address",
    "State",
    "LGA",
    "Blood Group",
    "Action",
    "Delete",
  ];

  useEffect(() => {
    getData().then((r) => {});
  }, [""]);

  const getData = async () => {
    await axios
      .get(`${serverLink}patient/list/all`, token)
      .then((result) => {
        if (result.data.length > 0) {
          setPatientList(result.data);
        } else {
          setPatientList([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const delete_item = async (id) => {
    toast.info("please wait...");
    await axios
      .delete(`${serverLink}patient/delete/${id}`, token)
      .then((res) => {
        if (res.data.message === "success") {
          toast.success("Deleted Successfully");
          getData();
        } else {
          toast.error("NETWORK ERROR. Please try again!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("NETWORK ERROR. Please try again!");
      });
  };

  const showTable = () => {
    try {
      return patientList.map((item, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.patientSerial}</td>
            <td>{item.firstName} {item.middleName} {item.surname}</td>
            <td>{item.gender}</td>
            <td>{item.phoneNumber}</td>
            <td>{item.emailAddress}</td>
            <td>{item.state}</td>
            <td>{item.lga}</td>
            <td>{item.bloodGroup}</td>
            <td className="d-flex">
              <Link to={`/patient/dashboard/${btoa(item.patientSerial)}`}
                 className={"btn btn-info btn-sm  mr-3"}
              >
              Dashboard
              </Link>
              <a href="#"
                className={"btn btn-primary btn-sm"}
                data-toggle="modal"
                data-target="#responsive-modal"
                onClick={() => {
                  setFormData({
                    ...formData,
                    patientID: item.patientID,
                    title: item.title,
                    firstName: item.firstName,
                    middleName: item.middleName,
                    surname: item.surname,
                    passport: item.passport,
                    emailAddress: item.emailAddress,
                    phoneNumber: item.phoneNumber,
                    gender: item.gender,
                    dateOfBirth: item.dateOfBirth,
                    bloodGroup: item.bloodGroup,
                    maritalStatus: item.maritalStatus,
                    nextOfKinName: item.nextOfKinName,
                    nextOfKinPhone: item.nextOfKinName,
                    nextOfKinRelationship: item.nextOfKinRelationship,
                    residentialAddress: item.residentialAddress,
                    nationality: item.nationality,
                    religion: item.religion,
                    occupation: item.occupation,
                    altPhoneNumber: item.altPhoneNumber,
                    state: item.state,
                    lga: item.lga,
                    patientSerial: item.patientSerial,
                    walletAmount: item.walletAmount,
                    password: item.password,
                    ward: item.ward,
                    zipCode: item.zipCode,
                    status: item.status,
                    officeAddress: item.officeAddress,
                  });
                }}
              >
                Edit
              </a>
            </td>
            <td>
              <a
                href="#"
                className={"btn btn-danger btn-sm "}
                onClick={() =>
                  showConfirm(
                    "Warning",
                    `Are you sure you want to delete this patient?`,
                    "warning"
                  ).then(async (confirm) => {
                    if (confirm) {
                      await delete_item(item.patientID);
                    }
                  })
                }
              >
                Delete
              </a>
            </td>
          </tr>
        );
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === 'nationality') {
      setStateList([])
      setLgaList([])
      setFormData({
        ...formData,
        state: "",
      })
      if (value === 'Nigeria') {
        stateData();
      } else {
        StateData.map(state => {
          if (state.state === 'Non-Nigerian')
            setStateList(stateList => [...stateList, state.state])
          //stateList.push(state.state)
        });
      }
    }

    if(e.target.name === 'state') {
      setLgaList([])
      StateData.map(state => {
        if(state.state === e.target.value) {
          state.lgas.map(lga => {
            setLgaList(lgaList => [...lgaList, lga])
          })
        }
      })
    }

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const resetItem = () => {
    setFormData({
      patientID: "",
      title: "",
      firstName: "",
      middleName: "",
      surname: "",
      passport: "",
      ImagePath: "",
      emailAddress: "",
      phoneNumber: "",
      gender: "",
      dateOfBirth: "",
      bloodGroup: "",
      maritalStatus: "",
      nextOfKinName: "",
      nextOfKinPhone: "",
      nextOfKinRelationship: "",
      residentialAddress: "",
      nationality: "",
      state: "",
      lga: "",
      patientSerial: "",
      occupation: "",
      altPhoneNumber: "",
      password: "",
      ward: "",
      zipCode: "",
      officeAddress: "",
      religion: "",
      submittedBy: `${props.loginData[0]?.userID}`,
    });
  };

  const onSubmit = async () => {
    if(formData.title === "") {
      showAlert("Empty Field", "Please Select the Patient Title", "error");
      return false;
    }

    if(formData.firstName === "") {
      showAlert("Empty Field", "Please Select the Patient First Name", "error");
      return false;
    }

    if(formData.surname === "") {
      showAlert("Empty Field", "Please Enter the Patient Surname", "error");
      return false;
    }

    if(formData.phoneNumber === "") {
      showAlert("Empty Field", "Please Enter the Patient Phone Number", "error");
      return false;
    }

    if(formData.gender === "") {
      showAlert("Empty Field", "Please Select the Patient Gender", "error");
      return false;
    }

    if(formData.bloodGroup === "") {
      showAlert("Empty Field", "Please Select the Patient Blood Group", "error");
      return false;
    }



    if(formData.dateOfBirth === "" ||  formData.dateOfBirth === null) {
      showAlert("Empty Field", "Please Select the Patient Date of Birth", "error");
      return false;
    }


    if(formData.maritalStatus === "") {
      showAlert("Empty Field", "Please Select the Patient Marital Status", "error");
      return false;
    }

    if(formData.nextOfKinName === "") {
      showAlert("Empty Field", "Please Enter the Patient Next of Kin Name", "error");
      return false;
    }

    if(formData.nextOfKinPhone === "") {
      showAlert("Empty Field", "Please Enter the Patient Next of Kin Phone Number", "error");
      return false;
    }

    if(formData.nextOfKinRelationship === "") {
      showAlert("Empty Field", "Please Select the Patient Next of Kin Relationship", "error");
      return false;
    }


    if(formData.nationality === "") {
      showAlert("Empty Field", "Please Select Nationality", "error");
      return false;
    }

    if(formData.state === "") {
      showAlert("Empty Field", "Please Select the Patient State", "error");
      return false;
    }

    if(formData.lga === "") {
      showAlert("Empty Field", "Please Select the Patient LGA", "error");
      return false;
    }

    if(formData.religion === "") {
      showAlert("Empty Field", "Please Select the Patient Religion", "error");
      return false;
    }


    if(formData.residentialAddress === "") {
      showAlert("Empty Field", "Please Select the Patient Residential Address", "error");
      return false;
    }

    const sendData = {
      ...formData,
      password: encryptData('123456789'),
      dateOfBirth: formatDate(formData.dateOfBirth),
    }

    if (formData.patientID === "") {
      axios
        .post(`${serverLink}patient/add`, sendData, token)
        .then(async (res) => {
          if (res.data.message === "success") {
            if (formData.ImagePath !== "") {
              const formData2 = new FormData();
              formData2.append('photo', formData.ImagePath);
              formData2.append('entry_id', res.data.patientId)
              axios.patch(`${serverLink}patient/uploadPatientPhoto`, formData2)
                  .then(async (result) => {
                    toast.success("Patient Added Successfully");
                    setIsFormLoading(false);
                    await getData();
                    document.getElementById("close").click();
                    resetItem();
                  })
                  .catch(err => {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong uploading the image. Please try again!",
                        "error"
                    );
                  });
            }else{
              toast.success("Patient Added Successfully");
              setIsFormLoading(false);
              await getData();
              document.getElementById("close").click();
              resetItem();
            }
          }
          if (res.data.message === "exist") {
            await showAlert(
              "Error",
              `Patient with given details already exist!`,
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }else {
      await axios
        .patch(`${serverLink}patient/update`, sendData, token)
        .then(async (result) => {
          if (result.data.message === "success") {
            if (formData.ImagePath !== "") {
              const formData2 = new FormData();
              formData2.append('photo', formData.ImagePath);
              formData2.append('entry_id', formData.patientID)
              axios.patch(`${serverLink}patient/uploadPatientPhoto`, formData2)
                  .then(async (result) => {
                    toast.success("Patient Updated Successfully");
                    setIsFormLoading(false);
                    await getData();
                    setIsSubmittingForm(false);
                    document.getElementById("close").click();
                    resetItem();
                  })
                  .catch(err => {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong uploading the image. Please try again!",
                        "error"
                    );
                  });
            }else{
              toast.success("Patient Updated Successfully");
              setIsFormLoading(false);
              await getData();
              setIsSubmittingForm(false);
              document.getElementById("close").click();
              resetItem();
            }
          } else {
            await showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          showAlert(
            "NETWORK ERROR",
            "Please check your connection and try again!",
            "error"
          );
          setIsSubmittingForm(false);
        });
    }
  };

  const stateData = () => {
    StateData.map(state => {
      if (state.state !== 'Non-Nigerian')
        setStateList(stateList => [...stateList, state.state])
    });
  }

  const onImageChange = (event) => {

    if (event.target.files && event.target.files[0]) {
      setFormData({
        ...formData,
        ImagePath: event.target.files[0],
        ImageData: event.target.files[0],
      });
      // let reader = new FileReader();
      //     reader.onload = (e) => {
      //         this.setState({
      //             htmlelement: (
      //                 <div className="addpersonpage">
      //                     <img
      //                         className="netimage"
      //                         srcSet={e.target.result}
      //                         alt="profileImage"
      //                     />
      //                 </div>
      //             ),
      //         });
      //     };
      //     reader.readAsDataURL(event.target.files[0]);
    }
  };


  return IsLoading ? (
    <Loader />
  ) : (
    <>
      <PageTitle title={["Home", "Patient"]} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Manage Patient</h4>
                <div className="d-flex no-block align-items-center m-b-30 mt-3">
                  <div className="ml-auto">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-info"
                        data-toggle="modal"
                        data-target="#responsive-modal"
                        onClick={resetItem}
                      >
                        <i className="fa fa-plus"></i> Add Patient
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                      tableID="Patient"
                      header={header}
                      body={showTable()}
                      title="Patient Report"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManagePatientForm
        title="Manage Patient"
        formData={formData}
        onEdit={onEdit}
        onSubmit={onSubmit}
        onImageChange={onImageChange}
        countryData={CountryData}
        stateList={stateList}
        lgaList={lgaList}
        IsFormLoading={IsFormLoading}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    }
  };
};


const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
    dashboardData: state.DashboardData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagePatient);

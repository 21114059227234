import React from "react";
export default function SliderForm(props) {
    return <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h4>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Title</label>
                                <input
                                    name="Title"
                                    className="form-control"
                                    id="Title"
                                    value={props.formData.Title}
                                    onChange={props.onEdit}
                                    placeholder="Image Title"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Caption</label>
                                <input
                                    name="Caption"
                                    className="form-control"
                                    id="Caption"
                                    value={props.formData.Caption}
                                    onChange={props.onEdit}
                                    placeholder="Slider Caption"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Button Name</label>
                                <input
                                    name="BtnName"
                                    className="form-control"
                                    id="BtnName"
                                    value={props.formData.BtnName}
                                    onChange={props.onEdit}
                                    placeholder="Slider Button Name"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Button Link</label>
                                <input
                                    name="BtnLink"
                                    className="form-control"
                                    id="BtnLink"
                                    value={props.formData.BtnLink}
                                    onChange={props.onEdit}
                                    placeholder="Slider Button Link"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Image Position</label>
                                <select className="form-control" name="ImagePosition" id="ImagePosition"
                                        value={props.formData.ImagePosition} onChange={props.onEdit}>
                                    <option value="">Select Image Position</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Image</label>
                                <input
                                    type="file"
                                    className="form-control form-control-solid"
                                    name="ImagePath"
                                    id="ImagePath"
                                    onChange={props.onImageChange}
                                    accept="image/*"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Mobile Image</label>
                                <input
                                    type="file"
                                    className="form-control form-control-solid"
                                    name="mobileImage"
                                    id="mobileImage"
                                    onChange={props.onMobileImageChange}
                                    accept="image/*"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React, { useState} from "react";
import {currencyConverter, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";
function OtherInformation(props) {
    const { patientSerial, patientName, walletAmount} = props;
    const token = props.loginData[0]?.token;
    const [IsLoading, setIsLoading] = useState(false)
    const [IsFormLoading, setIsFormLoading] = useState(false)


    const recordInitial = {
        patientSerial: props.patientSerial,
        patientName: props.patientName,
        familyType: "",
        parentChildren: "",
        positionInFamily: "",
        activities: "",
        exercise: "",
        lifestyle: "",
        leisureTime: "",
        smoking: "",
        alcohol: "",
        diet: "",
        hobbies: "",
        educationLevel: "",
        medicalCondition: "",
        previousAdmission: "",
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(recordInitial)

    const resetFormData = () => {
        setFormData(recordInitial)
    }

    const onEdit = async (e) => {
        setFormData({...formData, [e.target.id]: e.target.value})
    }

    const addOtherInfo = async () => {

        setIsFormLoading(true)
        toast.info("please wait...");
        await axios.post(`${serverLink}patient/other-info/add`, formData, token).then(async (result) => {
            if (result.data.message === "success") {
                toast.success("Record Added Successfully");
                setIsFormLoading(false);
                props.setRebuildOtherInformation(generate_token(5));
                document.getElementById("closeOtherInfo").click();
                resetFormData()
            } else {
                setIsFormLoading(false);
                toast.error("Something went wrong. Please try again!");
            }
        }) .catch((error) => {
            showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
            );
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: '#4b4949'}}>
                        <h2 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h2>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 col-4 b-r"><strong>Patient Name</strong>
                                <br/>
                                <p className="text-muted">{patientName}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Patient ID</strong>
                                <br/>
                                <p className="text-muted">{patientSerial}</p>
                            </div>
                            <div className="col-md-4 col-4 b-r"><strong>Wallet</strong>
                                <br/>
                                <p className="text-muted">{currencyConverter(walletAmount)}</p>
                            </div>
                            <div className="col-md-12"
                                 style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}></div>
                            <div className="col-md-12" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                <h4 style={{color: '#5687b7', fontWeight: 'bold'}}>Family  History</h4>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="familyType">Type of family </label>
                                <select
                                    name="familyType"
                                    id="familyType"
                                    className="custom-select"
                                    value={formData.familyType}
                                    onChange={onEdit}>
                                    <option>Select type of family</option>
                                    <option value="Monogamous">Monogamous</option>
                                    <option value="Polygamous">Polygamous</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="parentChildren">No. of children Parent have </label>
                                <input
                                    name="parentChildren"
                                    type="text"
                                    className="form-control"
                                    id="parentChildren"
                                    value={formData.parentChildren}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="positionInFamily">Position in the family </label>
                                <input
                                    name="positionInFamily"
                                    type="text"
                                    className="form-control"
                                    id="positionInFamily"
                                    value={formData.positionInFamily}
                                    onChange={onEdit}
                                />
                            </div>
                            <div className="col-md-12" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                <h4 style={{color: '#5687b7', fontWeight: 'bold'}}>Social History</h4>
                            </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="activities">Activities</label>
                                    <input
                                        name="activities"
                                        type="text"
                                        className="form-control"
                                        id="activities"
                                        value={formData.activities}
                                        onChange={onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="exercise">Exercise </label>
                                    <select
                                        name="exercise"
                                        id="exercise"
                                        className="custom-select"
                                        value={formData.exercise}
                                        onChange={onEdit}>
                                        <option>Select exercise level</option>
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Yearly">Yearly</option>
                                        <option value="None">None</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="lifestyle">Life Style </label>
                                    <input
                                        name="lifestyle"
                                        type="text"
                                        className="form-control"
                                        id="lifestyle"
                                        value={formData.lifestyle}
                                        onChange={onEdit}
                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label htmlFor="leisureTime">Leisure Time</label>
                                    <input
                                        name="leisureTime"
                                        type="text"
                                        className="form-control"
                                        id="leisureTime"
                                        value={formData.leisureTime}
                                        onChange={onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="smoking">Do you smoking? </label>
                                    <select
                                        name="smoking"
                                        id="smoking"
                                        className="custom-select"
                                        value={formData.smoking}
                                        onChange={onEdit}>
                                        <option>Select option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="alcohol">Do you drink alcohol? </label>
                                    <select
                                        name="alcohol"
                                        id="alcohol"
                                        className="custom-select"
                                        value={formData.alcohol}
                                        onChange={onEdit}>
                                        <option>Select option</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label htmlFor="diet">Diet</label>
                                    <select
                                        name="diet"
                                        id="diet"
                                        className="custom-select"
                                        value={formData.diet}
                                        onChange={onEdit}>
                                        <option>Select your diet</option>
                                        <option value="Atkins">Atkins</option>
                                        <option value="Zone">Zone</option>
                                        <option value="Ketogenic">Ketogenic</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="Vegan">Vegan</option>
                                        <option value="Weight Watchers">Weight Watchers</option>
                                        <option value="South Beach">South Beach</option>
                                        <option value="Raw Food">Raw Food</option>
                                        <option value="Mediterranean">Mediterranean</option>
                                    </select>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="hobbies">Hobbies </label>
                                    <input
                                        name="hobbies"
                                        type="text"
                                        className="form-control"
                                        id="hobbies"
                                        value={formData.hobbies}
                                        onChange={onEdit}
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="educationLevel">Education Level </label>
                                    <select
                                        name="educationLevel"
                                        id="educationLevel"
                                        className="custom-select"
                                        value={formData.educationLevel}
                                        onChange={onEdit}>
                                        <option>Select education level</option>
                                        <option value="Primary School">Primary School</option>
                                        <option value="Secondary School">Secondary School</option>
                                        <option value="Tertiary Institution">Tertiary Institution</option>
                                        <option value="None">None</option>
                                    </select>
                                </div>
                            <div className="col-md-12" style={{borderBottom: '2px solid #EEEEEE', marginBottom: '30px'}}>
                                <h4 style={{color: '#5687b7', fontWeight: 'bold'}}>Family  History</h4>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="medicalCondition">Have you had any medical condition before?</label>
                                <select
                                    name="medicalCondition"
                                    id="medicalCondition"
                                    className="custom-select"
                                    value={formData.medicalCondition}
                                    onChange={onEdit}>
                                    <option>Select option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="exercise">Have you ever been admitted in the hospital? </label>
                                <select
                                    name="previousAdmission"
                                    id="previousAdmission"
                                    className="custom-select"
                                    value={formData.previousAdmission}
                                    onChange={onEdit}>
                                    <option>Select option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={addOtherInfo}
                                        className="btn btn-danger waves-effect waves-light">{props.btn ?? "submit"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(OtherInformation);
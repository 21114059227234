import React, {useEffect, useState} from "react";
import {currencyConverter, formatDate, formatDateAndTime, generate_token, serverLink} from "../../url";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {connect} from "react-redux";
import Loader from "../../common/loader";
import {toast} from "react-toastify";

function MakePayment(props) {
   const {patientSerial, patientName, walletAmount, itemName, onPostPayment, onError, paymentFor, itemAmount, amountDue, paymentType} = props
    const [IsLoading, setIsLoading] = useState(true)
    const [patientWalletAmount, setPatientWalletAmount] = useState(0)

    const paymentInitialValue = {
        paymentMethod: "",
        submittedByName: `${props.loginData[0]?.firstName} ${props.loginData[0]?.middleName} ${props.loginData[0]?.surname}`,
        submittedBy: `${props.loginData[0]?.userID}`,
    }
    const [formData, setFormData] = useState(paymentInitialValue)


    useEffect( () => {
        getData();
    }, []);

    const getData = async () => {
        await axios.get(`${serverLink}patient/wallet/${patientSerial}`)
            .then((result) => {
                if (result.data.length > 0){
                    setPatientWalletAmount(result.data[0].walletAmount);
                }else {
                    setPatientWalletAmount(0)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const handlePayment = async () => {
        if (formData.paymentMethod === ""){
            toast.error('Please select payment method')
            return false
        }
        if (itemAmount === ""){
            toast.error('Please select item before making payment')
            return false
        }
      if (formData.paymentMethod === 'Wallet'){
          if (parseFloat(itemAmount) > parseFloat(patientWalletAmount)){
              toast.error('Insufficient funds in wallet!!!')
              return false
          }else{

          }
      }

        const sendData = {
            ...formData,
            patientSerial: patientSerial,
            paymentReference: formatDateAndTime(Date.now(), 'day') + generate_token(8),
            paymentAmount: itemAmount,
            paymentStatus: "Paid",
            paymentFor: paymentFor,
            paymentType: paymentType,
            amountDue: amountDue,
            amountPaid: itemAmount,
            itemName: itemName
        }
        onPostPayment(sendData)
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return IsLoading ? <Loader/> : <>
        <div id={`${
            typeof props.id !== "undefined" ? props.id : "responsive-modal"
        }`} className="modal fade bs-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title" id="myLargeModalLabel">{props.title ?? ""}</h1>
                        <button type="button" className={`${
                            typeof props.close !== "undefined" ? props.close : "closeModal"
                        }`}  id={`${
                            typeof props.close !== "undefined" ? props.close : "close"
                        }`} data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <h4 className="box-title m-t-5" >Payment Details</h4>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr>
                                        <td width="390">Patient ID:</td>
                                        <td>{patientSerial}</td>
                                    </tr>
                                    <tr>
                                        <td width="390">Name:</td>
                                        <td>{patientName}</td>
                                    </tr>
                                    <tr>
                                        <td width="390">Wallet Amount:</td>
                                        <td>{currencyConverter(patientWalletAmount)}</td>
                                    </tr>
                                    <tr>
                                        <td width="390">Payment For:</td>
                                        <td>{paymentFor}</td>
                                    </tr>
                                    <tr>
                                        <td width="390">Description:</td>
                                        <td>{itemName}</td>
                                    </tr>
                                    <tr>
                                        <td width="390">Amount Payable:</td>
                                        <td>{currencyConverter(itemAmount)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="mb-3 mt-4 form-group col-md-12">
                                <label className="form-label"><h3>Payment Option</h3></label>
                                <select className="form-control" name="paymentMethod" id="paymentMethod"
                                        value={formData.paymentMethod} onChange={onEdit}>
                                    <option value="">Select Payment Option</option>
                                    <option value="Wallet">Wallet ({currencyConverter(patientWalletAmount)})</option>
                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger waves-effect" data-dismiss="modal">Close
                        </button>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={handlePayment}
                                        className="btn btn-info waves-effect waves-light">{props.btn ?? "Pay Now"}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(MakePayment);